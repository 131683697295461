import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import IndividualApplicationForm from "./components/forms/individual-application/individual-application-form";
import JointApplicationForm from "./components/forms/joint-application/joint-application-form";
import IndividualWithOtherApplicationForm from "./components/forms/individual-with-other/individual-with-other";
import Layout from "./components/layout/layout";
import WelcomeBoard from "./welcome";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import RedirectAndAuth from "./components/redirect/redirect";
import { RequireAuth } from "./containers/routes/private-route";
import NotFound from "./components/not-found/not-found-component";
import OTPAuth from "./components/otp-auth/otp-auth";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<OTPAuth />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/joint"
            element={
              <RequireAuth>
                <JointApplicationForm />
              </RequireAuth>
            }
          />
          <Route
            path="/individual"
            element={
              <RequireAuth>
                <IndividualApplicationForm />
              </RequireAuth>
            }
          />
          <Route
            path="/co-individual"
            element={
              <RequireAuth>
                <IndividualWithOtherApplicationForm />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
