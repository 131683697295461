import React, { useEffect, useState } from "react";
import FormInput from "../../../containers/form/input/input-control";
import RadioInput from "../../../containers/form/radio/radio-component";
import SelectInput from "../../../containers/form/select/select-component";
import style from "../../../style.module.css";
import {
  ASSETS_VALIDATION,
  DEBTS_VALIDATION,
} from "../../../common/validations/asset-and-debts";
interface Props {
  applicantNo?: any;
  validateSchema: boolean;
  isPrevAvailable: boolean;
  isNextAvailable: boolean;
  handlePagination: (isNext: boolean) => any;
  isLastPage?: boolean;
  handleSubNavigation: (formValues: any, submitForm?: boolean) => any;
}

interface AssetValues {
  description: string;
  owner: string;
  is_subject_to_debt: string;
  value: string;
}

interface DebtValues {
  description: string;
  creditor: string;
  account_number: string;
  date_incurred: any;
  original_amount: string;
  balance: string;
  monthly_payment: string;
  past_due: string;
}

const AssetAndDebtForm: React.FC<Props> = ({
  applicantNo = 1,
  validateSchema,
  isPrevAvailable,
  isNextAvailable,
  handlePagination,
  handleSubNavigation,
  isLastPage,
}) => {
  const [assetKey, setAssetKey] = useState(0);
  const [debtKey, setDebtKey] = useState(0);
  const [assetDescription, setAssetDescription] = useState<string>("");
  const [owner, setOwner] = useState<string>("");
  const [subjectToDebt, setSubjectToDebt] = useState<string>("No");
  const [value, setValue] = useState<string>("");
  const [assets, setAssets] = useState<Array<AssetValues>>([]);
  const [triggerValidation, setTriggerValidation] = useState({});
  const [debtDescription, setDebtDescription] = useState<string>("");
  const [creditor, setCreditor] = useState<string>("");
  const [accNo, setAccNo] = useState<string>("");
  const [dateIncurred, setDateIncurred] = useState<any>();
  const [originalAmount, setOriginalAmount] = useState<string>("");
  const [balance, setBalance] = useState<string>("");
  const [monthlyPayment, setMonthlyPayment] = useState<string>("");
  const [pastDue, setPastDue] = useState<string>("");
  const [debts, setDebts] = useState<Array<DebtValues>>([]);
  const [assetsError, setAssetsError] = useState<boolean>(false);
  const [debtsError, setDebtsError] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({ assets: [], debts: [] });

  const triggerValidationForAllValues = () => {
    // if (debts.length == 0) setDebtsError(true);
    // if (assets.length == 0) setAssetsError(true);
    var trObj: any = {};
    Object.keys(formValues).forEach((item: any) => {
      trObj[item] = true;
    });
    setTriggerValidation(trObj);
  };

  const handleNextClick = () => {
    triggerValidationForAllValues();
    if (
      formValues["credit_references"] == undefined ||
      formValues["credit_references"].trim() == ""
    ) {
      return;
    } else if (
      formValues["additional_names"] == undefined ||
      formValues["additional_names"].trim() == ""
    ) {
      return;
    } else if (
      formValues["preferred_loan_officer"] == undefined ||
      formValues["preferred_loan_officer"].trim() == ""
    ) {
      return;
    }
    // else if (assetsError || debtsError) {
    //   return;
    // }
    localStorage.setItem(
      "_applicant_additional_names",
      formValues["additional_names"]
    );
    localStorage.setItem(
      "_applicant_credit_references",
      formValues["credit_references"]
    );
    localStorage.setItem(
      "_applicant_preferred_loan_officer",
      formValues["preferred_loan_officer"]
    );
    handleSubNavigation(formValues, true);
  };

  const handleInputChange = (target: any) => {
    var field = target.name;
    var trigObj: any = {};
    trigObj[field] = false;
    if (
      target.error ||
      !target.value ||
      target.value == undefined ||
      target.value.toString().trim() == ""
    ) {
      setTriggerValidation(trigObj);
    } else {
      trigObj[field] = true;
      setTriggerValidation(trigObj);
    }
    setFormValues({ ...formValues, [target.name]: target.value });
  };

  const triggerValidationForAssets = (status: boolean = true) => {
    var trObj: any = {};
    Object.entries(ASSETS_VALIDATION).forEach((item: any) => {
      trObj[item[0]] = status;
    });
    setTriggerValidation(trObj);
  };

  const triggerValidationForDebts = (status: boolean = true) => {
    var trObj: any = {};
    Object.entries(DEBTS_VALIDATION).forEach((item: any) => {
      trObj[item[0]] = status;
    });
    setTriggerValidation(trObj);
  };

  const getSectionValidationStatus = (
    sectionValidator: any,
    field: any,
    fieldValue: any
  ) => {
    const validation = sectionValidator[field as keyof typeof sectionValidator];
    if (validation) {
      if (validation.required && fieldValue == undefined) {
        return false;
      } else if (
        (validation.required && fieldValue.toString().trim() === "") ||
        fieldValue.toString().trim() == "0"
      ) {
        return false;
      } else if (validation.required && validation.validation != null) {
        if (!fieldValue.toString().match(validation.validation)) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    return true;
  };

  useEffect(() => {
    setFormValues(setAssetsAndDebts());
    window.scrollTo({ top: 0 });
  }, []);

  const setAssetsAndDebts = () => {
    var formValues: any = { assets: [], debts: [] };

    const assestAssestList: any = JSON.parse(
      localStorage.getItem(`assets_applicant_${applicantNo}_assets`) ?? "[]"
    );
    const assestDebtsList: any = JSON.parse(
      localStorage.getItem(`assets_applicant_${applicantNo}_debts`) ?? "[]"
    );

    if (assestAssestList.length > 0) {
      setAssets(assestAssestList);
      assestAssestList.forEach((item: any, index: number) => {
        formValues["assets"][index] = item;
      });
    }

    if (assestDebtsList.length > 0) {
      setDebts(assestDebtsList);
      assestDebtsList.forEach((item: any, index: number) => {
        formValues["debts"][index] = item;
      });
    }

    const assestsDetailsFormFields = ASSETS_VALIDATION;
    const debtsDetailsFormValidation = DEBTS_VALIDATION;

    assets.forEach((items: any, index: number) => {
      Object.entries(assestsDetailsFormFields).forEach((assetItm: any) => {
        if (assetItm[1].required) {
          if (
            items[assetItm[0]] == undefined ||
            items[assetItm[0]].toString().trim() == ""
          ) {
            items[assetItm[0]] = "";
          }
        }
      });
    });
    debts.forEach((items: any, index: number) => {
      Object.entries(debtsDetailsFormValidation).forEach((debtsItem: any) => {
        if (debtsItem[1].required) {
          if (
            items[debtsItem[0]] == undefined ||
            items[debtsItem[0]].toString().trim() == ""
          ) {
            items[debtsItem[0]] = "";
          }
        }
      });
    });
    formValues["credit_references"] = localStorage.getItem(
      "_applicant_credit_references"
    );
    formValues["additional_names"] = localStorage.getItem(
      "_applicant_additional_names"
    );
    formValues["preferred_loan_officer"] = localStorage.getItem(
      "_applicant_preferred_loan_officer"
    );
    return formValues;
  };

  useEffect(() => {
    if (validateSchema) {
      const formValues = setAssetsAndDebts();
      // if (
      //   formValues["assets"] == undefined ||
      //   formValues["assets"].length == 0
      // ) {
      //   setAssetsError(true);
      //   triggerValidationForAllValues();
      // }
      // if (formValues["debts"] == undefined || formValues["debts"].length == 0) {
      //   setDebtsError(true);
      //   triggerValidationForAllValues();
      // }
    }
  }, [validateSchema]);

  const handleSaveAsset = (event: any) => {
    event.preventDefault();
    let assetValues: any = {
      description: assetDescription,
      owner: owner,
      is_subject_to_debt: subjectToDebt,
      value: value,
    };
    var assetStatus = true;
    Object.keys(assetValues).forEach((key: any) => {
      assetStatus =
        assetStatus &&
        getSectionValidationStatus(ASSETS_VALIDATION, key, assetValues[key]);
    });
    if (!assetStatus) {
      triggerValidationForAssets();
      return;
    } else {
      setTriggerValidation({});
    }
    let assetsList: Array<any> = assets;
    assetsList.push(assetValues);
    localStorage.setItem(
      `assets_applicant_${applicantNo}_assets`,
      JSON.stringify(assetsList)
    );

    setAssets(assetsList);
    setFormValues({ ...formValues, assets: assetsList });
    setAssetsError(false);
    setAssetDescription("");
    setOwner("");
    setSubjectToDebt("No");
    setValue("");
  };

  const handleSaveDebt = (event: any) => {
    event.preventDefault();
    let debtValues: any = {
      description: debtDescription,
      creditor: creditor,
      account_number: accNo,
      date_incurred: dateIncurred,
      original_amount: originalAmount,
      balance: balance,
      monthly_payment: monthlyPayment,
      past_due: pastDue,
    };
    var debtsStatus = true;
    Object.keys(debtValues).forEach((key: string) => {
      debtsStatus =
        debtsStatus &&
        getSectionValidationStatus(DEBTS_VALIDATION, key, debtValues[key]);
    });
    if (!debtsStatus) {
      triggerValidationForDebts();
      return;
    } else {
      setTriggerValidation({});
    }
    let debtsList: Array<any> = debts;
    debtsList.push(debtValues);
    localStorage.setItem(
      `assets_applicant_${applicantNo}_debts`,
      JSON.stringify(debtsList)
    );

    setDebts(debtsList);
    setFormValues({ ...formValues, debts: debtsList });
    setDebtsError(false);
    setDebtDescription("");
    setCreditor("");
    setAccNo("");
    setDateIncurred("");
    setOriginalAmount("");
    setBalance("");
    setMonthlyPayment("");
    setPastDue("");
  };

  const editAsset = (index: number) => {
    setAssetDescription(assets[index].description);
    setOwner(assets[index].owner);
    setSubjectToDebt(assets[index].is_subject_to_debt);
    setValue(assets[index].value);
    deleteAsset(index);
  };

  const deleteAsset = (index: number) => {
    let assetList = assets;
    assetList.splice(index, 1);
    setAssets(assetList);
    localStorage.setItem(
      `assets_applicant_${applicantNo}_assets`,
      JSON.stringify(assetList)
    );
    setFormValues({ ...formValues, assets: assetList });
    setAssetKey(assetKey + 1);
  };

  const editDebt = (index: number) => {
    setDebtDescription(debts[index].description);
    setCreditor(debts[index].creditor);
    setAccNo(debts[index].account_number);
    setDateIncurred(debts[index].date_incurred);
    setOriginalAmount(debts[index].original_amount);
    setBalance(debts[index].balance);
    setMonthlyPayment(debts[index].monthly_payment);
    setPastDue(debts[index].past_due);

    deleteDebt(index);
  };

  const deleteDebt = (index: number) => {
    let debtList = debts;
    debtList.splice(index, 1);
    setDebts(debtList);
    localStorage.setItem(
      `assets_applicant_${applicantNo}_debts`,
      JSON.stringify(debtList)
    );
    setFormValues({ ...formValues, debts: debtList });
    setDebtKey(debtKey + 1);
  };

  const handlePreviousClick = (isNext: boolean) => {
    localStorage.setItem(
      `assets_applicant_${applicantNo}_debts`,
      JSON.stringify(debts)
    );
    localStorage.setItem(
      `assets_applicant_${applicantNo}_assets`,
      JSON.stringify(assets)
    );
    localStorage.setItem(
      "_applicant_additional_names",
      formValues["additional_names"]
    );
    localStorage.setItem(
      "_applicant_credit_references",
      formValues["credit_references"]
    );
    localStorage.setItem(
      "_applicant_preferred_loan_officer",
      formValues["preferred_loan_officer"]
    );
    handlePagination(isNext);
  };

  return (
    <div className={style["form-wrapper"]}>
      <div className={style["form-header"]}>
        <div className={style["form-box-wrapper"]}>
          <div className={style["form-sub-heading"]}>
            ASSETS (INCLUDE ASSETS THAT ARE JOINT WITH CO-APPLICANT)
          </div>
          <div className={style["form-sub-section"]}>
            <div className={style["form-table-wrapper"]}>
              {assets.length !== 0 && (
                <>
                  <div className={style["table-outer-wrapper"]}>
                    <div className={style["table-wrapper"]}>
                      <table key={assetKey} className="table">
                        <thead>
                          <tr>
                            <th>Description of Current Assets</th>
                            <th>Name(s) of Owners</th>
                            <th>Subject to Debt (Yes/No)</th>
                            <th>Amount/Value</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>

                        <tbody>
                          {" "}
                          {assets.map((asset: AssetValues, index) => {
                            return (
                              <tr>
                                <td>{asset.description}</td>
                                <td>{asset.owner}</td>
                                <td>{asset.is_subject_to_debt}</td>
                                <td>{asset.value}</td>
                                <td>
                                  <div
                                    className={`${style["form-buttons-wrapper"]}`}
                                  >
                                    <a
                                      onClick={(event) => {
                                        event.preventDefault();
                                        editAsset(index);
                                      }}
                                      className={`${style["table-btn"]}`}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                      </svg>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className={style["form-buttons-wrapper"]}
                                  >
                                    <a
                                      onClick={(event) => {
                                        event.preventDefault();
                                        deleteAsset(index);
                                      }}
                                      className={` ${style["table-btn"]}`}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                      >
                                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                      </svg>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
            <FormInput
              label={"Description of Asset"}
              name={`asset_description`}
              value={assetDescription}
              type="text"
              handleInputChange={(target: any) => {
                setAssetDescription(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Name(s) of Owners"}
              name={`owner`}
              value={owner}
              type="text"
              handleInputChange={(target: any) => {
                setOwner(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <RadioInput
              key={`is_sub_to_debt`}
              handleInputChange={(target: any) => {
                setSubjectToDebt(target.value);
              }}
              label={"Subject to Debt"}
              name={`is_sub_to_debt`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={subjectToDebt ?? "Yes"}
            />
            <FormInput
              label={"Amount/Value"}
              name={`value`}
              value={value}
              type="text"
              handleInputChange={(target: any) => {
                setValue(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <div
              className={`${style["form-buttons-wrapper"]} ${style["partial-form-button"]}`}
            >
              <a
                onClick={(event) => {
                  handleSaveAsset(event);
                }}
              >
                Save Asset
              </a>
              {assetsError && (
                <div className={style["form-label-error"]}>
                  {" "}
                  <p>Add alteast one asset</p>
                </div>
              )}
            </div>
          </div>

          <div className={style["form-sub-heading"]}>
            DEBTS (INCLUDE DEBTS THAT ARE JOINT WITH CO-APPLICANT)
          </div>
          <div className={style["form-sub-section"]}>
            <div className={style["form-table-wrapper"]}>
              {debts.length !== 0 && (
                <>
                  <div className={style["table-outer-wrapper"]}>
                    <div className={style["table-wrapper"]}>
                      <table key={debtKey} className="table">
                        <thead>
                          <tr>
                            <th>Debt Description</th>
                            <th>Creditor's Name/Address</th>
                            <th>Account #</th>
                            <th>Date Incurred</th>
                            <th>Original Amount</th>
                            <th>Present Balance</th>
                            <th>Monthly Payment</th>
                            <th>Amount Past Due</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>

                        <tbody>
                          {debts.map((debt: DebtValues, index) => {
                            return (
                              <tr>
                                <td>{debt.description}</td>
                                <td>{debt.creditor}</td>
                                <td>{debt.account_number}</td>
                                <td>{debt.date_incurred}</td>
                                <td>{debt.original_amount}</td>
                                <td>{debt.balance}</td>
                                <td>{debt.monthly_payment}</td>
                                <td>{debt.past_due}</td>
                                <td>
                                  <div
                                    className={`${style["form-buttons-wrapper"]}`}
                                  >
                                    <a
                                      onClick={(event) => {
                                        event.preventDefault();
                                        editDebt(index);
                                      }}
                                      className={`${style["table-btn"]}`}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                      >
                                        <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                      </svg>
                                    </a>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className={`${style["form-buttons-wrapper"]}`}
                                  >
                                    <a
                                      onClick={(event) => {
                                        event.preventDefault();
                                        deleteDebt(index);
                                      }}
                                      className={`${style["table-btn"]}`}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                      >
                                        <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                      </svg>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </div>
            <FormInput
              label={"Debt Description"}
              name={`debt_description`}
              value={debtDescription}
              type="text"
              handleInputChange={(target: any) => {
                setDebtDescription(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Creditor's Name/Address"}
              name={`creditor`}
              value={creditor}
              type="text"
              handleInputChange={(target: any) => {
                setCreditor(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Account Number"}
              name={`account_number`}
              value={accNo}
              type="text"
              handleInputChange={(target: any) => {
                setAccNo(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Date Incurred"}
              name={`date_incurred`}
              value={dateIncurred}
              type="date"
              handleInputChange={(target: any) => {
                setDateIncurred(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Original Amount"}
              name={`original_amt`}
              value={originalAmount}
              type="number"
              handleInputChange={(target: any) => {
                setOriginalAmount(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Present Balance"}
              name={`balance`}
              value={balance}
              type="number"
              handleInputChange={(target: any) => {
                setBalance(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Monthly Payment"}
              name={`monthly_payment`}
              value={monthlyPayment}
              type="number"
              handleInputChange={(target: any) => {
                setMonthlyPayment(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Amount Past Due"}
              name={`past_due`}
              value={pastDue}
              type="number"
              handleInputChange={(target: any) => {
                setPastDue(target.value);
              }}
              triggerValidation={triggerValidation}
            />
            <div
              className={`${style["form-buttons-wrapper"]} ${style["partial-form-button"]}`}
            >
              <a
                onClick={(event) => {
                  handleSaveDebt(event);
                }}
              >
                Save Debt
              </a>
              {debtsError && (
                <div className={style["form-label-error"]}>
                  {" "}
                  <p>Add alteast one debt</p>
                </div>
              )}
            </div>
          </div>
          <div className={style["form-sub-heading"]}>Reference Information</div>
          <div className={style["form-sub-section"]}>
            <FormInput
              label={"Credit References"}
              name={`credit_references`}
              value={
                formValues["credit_references"]
                  ? formValues["credit_references"]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Additional Names"}
              name={`additional_names`}
              value={
                formValues["additional_names"]
                  ? formValues["additional_names"]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <SelectInput
              handleInputChange={handleInputChange}
              label="Preferred Loan Officer"
              name={`preferred_loan_officer`}
              options={[
                "John Kavyavu + Leila Drici (French and English)",
                "Martha Leuro (Spanish and English)",
                "Vicki Davis (English)",
                "First Available",
              ]}
              triggerValidation={triggerValidation}
              selectedOption={
                formValues["preferred_loan_officer"]
                  ? formValues["preferred_loan_officer"]
                  : ""
              }
            />
          </div>
        </div>
      </div>
      <div
        className={`${
          isPrevAvailable
            ? style["form-buttons-wrapper"]
            : style["form-buttons-wrapper"] + " " + style["single-button"]
        }`}
      >
        {isPrevAvailable && (
          <a
            onClick={() => {
              handlePreviousClick(false);
            }}
            className={style.secondary}
          >
            Previous
          </a>
        )}
        {isLastPage && (
          <a
            onClick={() => {
              handleNextClick();
            }}
          >
            Submit Application
          </a>
        )}
      </div>
    </div>
  );
};

export default AssetAndDebtForm;
