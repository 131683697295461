import React, { useEffect, useState } from "react";
import style from "../../../style.module.css";
import { getApplicantValidations } from "../../../common/validations/applicant-fields";
import { LOAN_DETAILS_VALIDATIONS } from "../../../common/validations/loan-fields";
import { ASSETS_VALIDATION, DEBTS_VALIDATION } from "../../../common/validations/asset-and-debts";

type Validation = {
  required: boolean;
  message: string;
  name: string;
  value: any;
  setFieldValid:(valid:any)=>any;
};

const ErrorLabel: React.FC<Validation> = ({ name, value , setFieldValid}) => {
  const [error, setError] = useState<string>("");

  useEffect(() => {
    handleError();
  }, [value]);

  const handleError = () => {
    const validationFields = getApplicantValidations((name.split("_")).at(-1));
    var validation : any = validationFields[name];
    if (!validation) {
      const otherValidationFields = {...LOAN_DETAILS_VALIDATIONS, ...ASSETS_VALIDATION, ...DEBTS_VALIDATION}
      validation = otherValidationFields[name as keyof typeof otherValidationFields];
    }
    if (validation) {
      if(validation.required && value ==undefined){
        setError(validation.message ? validation.message + " is required" : "");
        setFieldValid(false)
      }
      else if (
        (validation.required && value.toString().trim() === "") ||
        value.toString().trim() == "0"
      ) {
        setError(validation.message ? validation.message + " is required" : "");
        setFieldValid(false)
      } else if (validation.required && validation.validation != null) {
        if (!value.toString().match(validation.validation)) {
          setError(
            validation.message ? "Enter a valid value for " + validation.message : ""
          );
          setFieldValid(false)
        }
        else{
          setError("")
          setFieldValid(true)
        }
      } else {
        setError("");
        setFieldValid(true)
      }
    }
  };

  return (
    <>
      {error && (
        <div className={style["form-label-error"]}>
          {" "}
          <p>{error}</p>
        </div>
      )}
    </>
  );
};

export default ErrorLabel;
