import { generateChallenge } from "../utils/helpers";
import axios from "axios";
class AuthService {
  private static _instance: AuthService;

  private constructor() {}

  public static get init() {
    if (this._instance) {
      return this._instance;
    }
    this._instance = new this();
    return this._instance;
  }
  private config = {
    "x-api-key": process.env.REACT_APP_API_KEY,
    Accept: "application/json",
  };

  public async getAuthToken(code_verifier: string) {
    var pkce_state = window.localStorage.getItem("pkce_state") + "";
    var code_challenge = await generateChallenge(code_verifier);
    var auth_url =
      process.env.REACT_APP_AUTH_ENDPOINT +
      "?response_type=code" +
      "&client_id=" +
      encodeURIComponent("4bf00b1b-c2cb-4cde-82d2-d1100eb64281") +
      "&redirect_uri=" +
      encodeURIComponent(process.env.REACT_APP_REDIRECT_URI) +
      "&scope=" +
      encodeURIComponent(
        "openid https://api.banno.com/consumer/auth/user.profile.readonly"
      ) +
      "&state=" +
      encodeURIComponent(pkce_state) +
      "&code_challenge=" +
      encodeURIComponent(code_challenge) +
      "&code_challenge_method=S256";
    window.location.replace(auth_url);
  }

  public async sendOtp(email: string, token: string) {
    const res = await axios.post(
      process.env.REACT_APP_XRV3_BASE_URL + "/otp",
      {
        email: email,
        domain_url: window.location.href,
        captcha_token: token,
      },
      {
        headers: this.config,
      }
    );
    return res;
  }

  public async verifyOtp(email: string, otp: string) {
    const res = await axios.post(
      process.env.REACT_APP_XRV3_BASE_URL + "/otp/verify",
      {
        otp: otp,
        email: email,
        domain_url: window.location.href,
      },
      { headers: this.config }
    );
    return res;
  }
}

export default AuthService;
