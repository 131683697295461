import { useLocation, Navigate } from "react-router-dom";
import { isTokenExpired } from "../../utils/helpers";

export function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const token = localStorage.getItem("access_token") + "";

  var isAuthenticated = token.length > 0 ? true : false;

  if (isAuthenticated) {
    isAuthenticated = !isTokenExpired(token);
  }

  if (!isAuthenticated)
    return <Navigate to="/" state={{ from: location }} replace />;
  else return children;
}
