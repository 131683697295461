export const ASSETS_VALIDATION = {
  asset_description: {
    required: false,
    name: "asset_description",
    message: "",
    validation: null,
  },
  owner: {
    required: true,
    name: "owner",
    message: "Name(s) of Owners",
    validation: null,
  },
  is_sub_to_debt: {
    required: true,
    name: "is_sub_to_debt",
    message: "Subject to Debt",
    validation: null,
  },
  value: {
    required: true,
    name: "value",
    message: "Amount/Value",
    validation: null,
  },
};

export const DEBTS_VALIDATION = {
  debt_description: {
    required: false,
    name: "debt_description",
    message: "",
    validation: null,
  },
  creditor: {
    required: true,
    name: "value",
    message: "Creditor's Name/Address",
    validation: null,
  },
  account_number: {
    required: true,
    name: "account_number",
    message: "Account Number",
    validation: null,
  },
  date_incurred: {
    required: true,
    name: "date_incurred",
    message: "Date Incurred",
    validation: null,
  },
  original_amt: {
    required: true,
    name: "original_amt",
    message: "Original Amount",
    validation: null,
  },
  balance: {
    required: true,
    name: "balance",
    message: "Present Balance",
    validation: null,
  },
  monthly_payment: {
    required: true,
    name: "monthly_payment",
    message: "Monthly Payment",
    validation: null,
  },
  past_due: {
    required: true,
    name: "past_due",
    message: "Amount Past Due",
    validation: null,
  },
  credit_references: {
    required: true,
    name: "credit_references",
    message: "Credit References",
    validation: null,
  },
  additional_names: {
    required: true,
    name: "additional_names",
    message: "Additional Names",
    validation: null,
  },
  preferred_loan_officer: {
    required: true,
    name: "preferred_loan_officer",
    message: "Preferred Loan Officer",
    validation: null,
  },
};
