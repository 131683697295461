import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApplicationService from "../../services/application-service";
import style from "../../style.module.css";

const Dashboard: React.FC = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [noOfApplicants, setNoOfApplicants] = useState(2);
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState<boolean>(false);
  const [loanProduct, setLoanProduct] = useState("");

  useEffect(() => {
    getFormData();
  }, []);

  const navigate = useNavigate();

  const getFormData = async () => {
    try {
      const response = await ApplicationService.init.getFormData();
      setFormValues(response);
    } catch (error) {
      setFormValues({
        customer_id: "",
        first_name_1: "",
        last_name_1: "",
        email_1: "",
        phone_1: "",
        address_1: "",
        requested_amount: 0,
        term_requested: 0,
        payment_date: new Date().toISOString().substring(0, 10),
        payment_type: "",
        purpose: "",
        credit_references: "",
        additional_names: "",
        assets: [],
        debts: [],
        applicants: [],
        ssn_1: "",
        preferred_loan_officer: "",
      });
    }
  };

  const handleOptionChange = (option: any) => {
    setError(false);
    setSelectedOption(option);
  };

  const handleApplicantNoChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNoOfApplicants(Number(event.target.value));
  };

  const handleLoanProductClick = (option: any) => {
    setNoOfApplicants(2);
    setLoanProduct(option);
  };

  const handleButtonClick = () => {
    setError(false);
    if (selectedOption === "joint") {
      navigate("/joint", { state: { initialValues: formValues } });
    } else if (selectedOption === "individual") {
      navigate("/individual", { state: { initialValues: formValues } });
    } else if (selectedOption === "individualWithOther") {
      navigate("/co-individual", {
        state: { initialValues: formValues, noOfApplicants: noOfApplicants },
      });
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className={style["top-logo"]}>
        <div className={style["logo-section"]}>
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="3090.000000pt"
            height="40.000000pt"
            viewBox="0 0 3090.000000 1445.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,1445.000000) scale(0.100000,-0.100000)"
              fill="#e63b2f"
              stroke="none"
            >
              <path
                d="M6403 13326 l-28 -24 -3 -5959 c-2 -4464 1 -5965 9 -5986 26 -62 104
-77 158 -31 l31 26 0 5973 0 5972 -26 27 c-36 35 -101 36 -141 2z"
              />
              <path d="M3365 10949 c-408 -47 -705 -382 -705 -794 0 -209 70 -390 209 -538 162 -172 356 -257 586 -257 230 0 424 85 586 257 139 148 209 329 209 538 0 139 -23 235 -84 360 -98 197 -279 349 -486 408 -47 14 -224 40 -240 36 -3 -1 -36 -5 -75 -10z" />
              <path d="M5045 9380 c-516 -261 -981 -412 -1431 -465 -150 -18 -457 -20 -594 -5 -318 35 -646 149 -881 306 -99 66 -241 183 -271 224 -17 23 -25 27 -40 19 -17 -9 -16 -13 12 -62 562 -959 668 -2451 295 -4157 -72 -332 -170 -685 -284 -1023 -33 -97 -54 -174 -48 -170 7 4 75 51 151 106 1016 727 1594 891 2261 642 252 -94 542 -271 860 -524 165 -132 153 -125 170 -111 12 10 4 25 -44 88 -304 399 -509 924 -591 1511 -63 456 -51 1033 35 1586 72 465 196 964 360 1445 68 200 188 511 244 634 17 38 30 70 29 71 -2 0 -106 -51 -233 -115z" />
              <path d="M9859 9317 c-90 -26 -169 -102 -199 -192 -21 -62 -16 -156 9 -209 29 -58 90 -119 149 -148 69 -33 178 -32 247 4 64 33 127 100 150 161 23 61 17 175 -13 232 -26 50 -84 107 -139 134 -54 27 -144 35 -204 18z" />
              <path d="M11390 9319 c-127 -19 -234 -73 -315 -160 -104 -111 -125 -214 -132 -656 l-6 -323 -178 0 -179 0 0 -155 0 -155 180 0 180 0 0 -1245 0 -1245 180 0 180 0 0 1245 0 1245 295 0 295 0 0 155 0 155 -296 0 -296 0 4 323 c3 309 5 324 26 368 24 50 75 92 139 115 83 29 264 9 401 -45 l52 -21 0 169 0 169 -107 26 c-182 43 -305 53 -423 35z" />
              <path d="M28360 8700 l0 -520 -245 0 -245 0 0 -155 0 -155 245 0 245 0 0 -1245 0 -1245 180 0 180 0 0 1245 0 1245 285 0 285 0 0 155 0 155 -285 0 -285 0 0 520 0 520 -180 0 -180 0 0 -520z" />
              <path d="M7500 7275 l0 -1895 915 0 915 0 0 185 0 185 -725 0 -725 0 0 1710 0 1710 -190 0 -190 0 0 -1895z" />
              <path d="M17710 7334 c-476 -1010 -877 -1863 -892 -1895 l-27 -59 208 0 207 0 294 620 293 620 821 0 822 0 295 -620 294 -619 202 -1 201 0 -96 208 c-54 114 -450 966 -882 1894 l-785 1688 -45 0 -45 -1 -865 -1835z m1237 344 l321 -683 -646 -3 c-355 -1 -647 -1 -649 1 -4 5 640 1367 647 1367 4 0 151 -307 327 -682z" />
              <path d="M13306 8240 c-329 -38 -575 -152 -799 -369 -253 -245 -408 -549 -452 -888 -63 -480 91 -939 430 -1285 174 -177 385 -292 635 -346 358 -77 746 -38 1033 104 273 134 473 333 641 636 l37 66 -144 76 c-79 42 -148 76 -153 76 -5 0 -34 -40 -64 -88 -70 -111 -195 -266 -255 -316 -127 -105 -300 -190 -480 -237 -78 -20 -114 -24 -270 -24 -157 0 -191 3 -265 23 -195 54 -308 120 -460 272 -86 86 -128 137 -167 204 -95 161 -147 331 -160 519 l-6 97 1258 0 1258 0 -6 108 c-17 286 -99 543 -245 762 -73 110 -239 281 -349 360 -164 117 -370 201 -578 235 -115 19 -337 26 -439 15z m436 -348 c302 -81 526 -245 661 -483 41 -73 135 -325 124 -335 -2 -2 -472 -3 -1044 -2 l-1041 3 32 95 c140 413 412 660 810 736 105 20 359 12 458 -14z" />
              <path d="M21465 8239 c-91 -13 -213 -54 -284 -96 -84 -49 -206 -171 -254 -253 -61 -106 -89 -206 -94 -345 -12 -309 108 -519 412 -720 44 -28 159 -95 256 -147 278 -151 403 -241 463 -336 75 -120 87 -280 29 -402 -90 -190 -265 -300 -480 -300 -186 0 -360 80 -527 244 -32 31 -61 56 -64 55 -4 0 -55 -56 -114 -124 l-108 -123 46 -51 c66 -73 192 -165 297 -216 328 -158 701 -148 961 26 88 58 198 172 250 257 89 147 131 347 108 516 -41 308 -207 489 -687 751 -303 165 -422 260 -486 390 -44 90 -52 201 -20 287 77 202 302 309 537 253 121 -28 258 -105 370 -208 44 -40 59 -49 72 -41 9 6 61 59 117 118 l100 108 -45 40 c-288 251 -573 357 -855 317z" />
              <path d="M23455 8239 c-91 -13 -213 -54 -284 -96 -84 -49 -206 -171 -254 -253 -61 -106 -89 -206 -94 -345 -12 -309 108 -519 412 -720 44 -28 159 -95 256 -147 278 -151 403 -241 463 -336 75 -120 87 -280 29 -402 -90 -190 -265 -300 -480 -300 -186 0 -360 80 -527 244 -32 31 -61 56 -64 55 -4 0 -55 -56 -114 -124 l-108 -123 46 -51 c66 -73 192 -165 297 -216 328 -158 701 -148 961 26 88 58 198 172 250 257 89 147 131 347 108 516 -41 308 -207 489 -687 751 -303 165 -422 260 -486 390 -44 90 -52 201 -20 287 77 202 302 309 537 253 121 -28 258 -105 370 -208 44 -40 59 -49 72 -41 9 6 61 59 117 118 l100 108 -45 40 c-288 251 -573 357 -855 317z" />
              <path d="M26016 8240 c-329 -38 -575 -152 -799 -369 -253 -245 -408 -549 -452 -888 -63 -480 91 -939 430 -1285 174 -177 385 -292 635 -346 358 -77 746 -38 1033 104 273 134 473 333 641 636 l37 66 -144 76 c-79 42 -148 76 -153 76 -5 0 -34 -40 -64 -88 -70 -111 -195 -266 -255 -316 -127 -105 -300 -190 -480 -237 -78 -20 -114 -24 -270 -24 -157 0 -191 3 -265 23 -195 54 -308 120 -460 272 -86 86 -128 137 -167 204 -95 161 -147 331 -160 519 l-6 97 1258 0 1258 0 -6 108 c-17 286 -99 543 -245 762 -73 110 -239 281 -349 360 -164 117 -370 201 -578 235 -115 19 -337 26 -439 15z m436 -348 c302 -81 526 -245 661 -483 41 -73 135 -325 124 -335 -2 -2 -472 -3 -1044 -2 l-1041 3 32 95 c140 413 412 660 810 736 105 20 359 12 458 -14z" />
              <path d="M9760 6780 l0 -1400 180 0 180 0 0 1400 0 1400 -180 0 -180 0 0 -1400z" />
            </g>
          </svg>
        </div>
      </div>
      <div className={style["dashboard-wrapper"]}>
        <div className={style["dashboard-box"]}>
          <h3>Apply for Loan</h3>
          <div className={style["dashboard-steps-wrapper"]}>
            <span
              className={
                loanProduct != ""
                  ? `${style["step-no"]} ${style["active"]}`
                  : `${style["step-no"]}`
              }
            >
              1
            </span>
            <span className={style["step-line"]}></span>
            <span
              className={
                loanProduct != "" && selectedOption != ""
                  ? `${style["step-no"]} ${style["active"]}`
                  : `${style["step-no"]}`
              }
            >
              2
            </span>
          </div>
          <div
            className={
              loanProduct == ""
                ? `${style["loan-cards-wrapper"]}`
                : `${style["loan-cards-wrapper"]} ${style["first-card"]}`
            }
          >
            <a
              key={"unsecuredconsumerloan"}
              onClick={() => {
                handleLoanProductClick("unsecuredconsumerloan");
              }}
              className={
                loanProduct != "unsecuredconsumerloan"
                  ? `${style["each-cards"]}`
                  : `${style["each-cards"]} ${style["active"]}`
              }
            >
              <div className={style["selected-div"]}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>
              </div>
              <div className={style["svg-holder"]}>
                <div className={style["svg-wrap"]}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M312 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z" />
                  </svg>
                </div>
              </div>
              <div className={style["text-holder"]}>
                Unsecured Consumer Loan
              </div>
            </a>
            {/* <a
              key={"mobilehomeloan"}
              onClick={() => {
                handleLoanProductClick("mobilehomeloan");
              }}
              className={
                loanProduct != "mobilehomeloan"
                  ? `${style["each-cards"]}`
                  : `${style["each-cards"]} ${style["active"]}`
              }
            >
              <div className={style["selected-div"]}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>
              </div>
              <div className={style["svg-holder"]}>
                <div className={style["svg-wrap"]}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-4 0 70 70"
                    height="70px"
                    width="70px"
                  >
                    <path
                      fill="#666"
                      d="M62.79,29.172l-28-28C34.009,0.391,32.985,0,31.962,0s-2.047,0.391-2.828,1.172l-28,28
	c-1.562,1.566-1.484,4.016,0.078,5.578c1.566,1.57,3.855,1.801,5.422,0.234L8,33.617V60c0,2.211,1.789,4,4,4h16V48h8v16h16
	c2.211,0,4-1.789,4-4V33.695l1.195,1.195c1.562,1.562,3.949,1.422,5.516-0.141C64.274,33.188,64.356,30.734,62.79,29.172z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div className={style["text-holder"]}>Mobile Home Loans</div>
            </a>
            <a
              key={"landloans"}
              onClick={() => {
                handleLoanProductClick("landloans");
              }}
              className={
                loanProduct != "landloans"
                  ? `${style["each-cards"]}`
                  : `${style["each-cards"]} ${style["active"]}`
              }
            >
              <div className={style["selected-div"]}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                </svg>
              </div>
              <div className={style["svg-holder"]}>
                <div className={style["svg-wrap"]}>
                  <svg
                    version="1.0"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 64 64"
                  >
                    <g>
                      <path
                        fill="#666"
                        d="M60,0H4C1.789,0,0,1.789,0,4v56c0,2.211,1.789,4,4,4h56c2.211,0,4-1.789,4-4V4C64,1.789,62.211,0,60,0z
		 M8,8h48v32.688l-9.113-9.113c-1.562-1.559-4.094-1.559-5.656,0L16.805,56H8V8z"
                      />
                      <circle fill="#666" cx="24" cy="24" r="8" />
                    </g>
                  </svg>
                </div>
              </div>
              <div className={style["text-holder"]}>Land Loans</div>
            </a> */}
          </div>
          {loanProduct != "" && (
            <>
              <div className={style["loan-cards-wrapper"]}>
                <a
                  key={"individual"}
                  onClick={() => {
                    handleOptionChange("individual");
                  }}
                  className={
                    selectedOption === "individual"
                      ? `${style["each-cards"]} ${style["active"]}`
                      : `${style["each-cards"]}`
                  }
                >
                  <div className={style["selected-div"]}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                  </div>
                  <div className={style["selected-div"]}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                  </div>
                  <div className={style["svg-holder"]}>
                    <div className={style["svg-wrap"]}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z" />
                      </svg>
                    </div>
                  </div>
                  <div className={style["text-holder"]}>Individual Loan</div>
                </a>
                {/* <a
                  key={"individualWithOther"}
                  onClick={() => {
                    handleOptionChange("individualWithOther");
                  }}
                  className={
                    selectedOption === "individualWithOther"
                      ? `${style["each-cards"]} ${style["active"]}`
                      : `${style["each-cards"]}`
                  }
                >
                  <div className={style["selected-div"]}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                  </div>
                  <div className={style["svg-holder"]}>
                    <div className={style["svg-wrap"]}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z" />
                      </svg>
                    </div>
                  </div>
                  <div className={style["text-holder"]}>
                    Individual With Others Loan
                  </div>
                </a> */}
                {/* <a
                  key={"joint"}
                  onClick={() => {
                    handleOptionChange("joint");
                  }}
                  className={
                    selectedOption === "joint"
                      ? `${style["each-cards"]} ${style["active"]}`
                      : `${style["each-cards"]}`
                  }
                >
                  <div className={style["selected-div"]}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                    </svg>
                  </div>
                  <div className={style["svg-holder"]}>
                    <div className={style["svg-wrap"]}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path d="M64 64a64 64 0 1 1 128 0A64 64 0 1 1 64 64zM25.9 233.4C29.3 191.9 64 160 105.6 160h44.8c27 0 51 13.4 65.5 34.1c-2.7 1.9-5.2 4-7.5 6.3l-64 64c-21.9 21.9-21.9 57.3 0 79.2L192 391.2V464c0 26.5-21.5 48-48 48H112c-26.5 0-48-21.5-48-48V348.3c-26.5-9.5-44.7-35.8-42.2-65.6l4.1-49.3zM448 64a64 64 0 1 1 128 0A64 64 0 1 1 448 64zM431.6 200.4c-2.3-2.3-4.9-4.4-7.5-6.3c14.5-20.7 38.6-34.1 65.5-34.1h44.8c41.6 0 76.3 31.9 79.7 73.4l4.1 49.3c2.5 29.8-15.7 56.1-42.2 65.6V464c0 26.5-21.5 48-48 48H496c-26.5 0-48-21.5-48-48V391.2l47.6-47.6c21.9-21.9 21.9-57.3 0-79.2l-64-64zM272 240v32h96V240c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l64 64c9.4 9.4 9.4 24.6 0 33.9l-64 64c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V336H272v32c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-64-64c-9.4-9.4-9.4-24.6 0-33.9l64-64c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2z" />
                      </svg>
                    </div>
                  </div>
                  <div className={style["text-holder"]}>Joint Loan</div>
                </a> */}
              </div>
              {selectedOption === "individualWithOther" && (
                <div className={style["form-box-wrapper"]}>
                  <div
                    className={`${style["form-box"]} ${style["full-width"]}`}
                  >
                    <div className={style["form-label"]}>
                      Number Of Applicants
                    </div>
                    <div className={style["input-box"]}>
                      <select
                        id="noOfApplicants"
                        value={noOfApplicants}
                        onChange={handleApplicantNoChange}
                      >
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                      </select>
                      <div className={style["down-arrow"]}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {loanProduct != "" && selectedOption != "" && (
            <div
              className={`${style["form-buttons-wrapper"]} ${style["single-button"]}`}
            >
              <a onClick={handleButtonClick}>Next</a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
