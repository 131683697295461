import React, { useEffect, useState } from "react";
import style from "../../../style.module.css";
import FormInput from "../../../containers/form/input/input-control";
import RadioInput from "../../../containers/form/radio/radio-component";
import SelectInput from "../../../containers/form/select/select-component";
import Address from "../../../containers/address/address";
import { getApplicantValidations } from "../../../common/validations/applicant-fields";
import { useLocation } from "react-router-dom";
import CheckBoxInput from "../../../containers/form/checkbox/checkbox-input";

interface Props {
  applicantNo?: any;
  validateSchema: boolean;
  isPrevAvailable: boolean;
  isNextAvailable: boolean;
  isLastPage?: boolean;
  handlePagination: (isNext: boolean) => any;
  handleSubNavigation: (formValues: any, submitForm?: boolean) => any;
}

const ApplicantDetailsForm: React.FC<Props> = ({
  applicantNo = 1,
  validateSchema,
  isPrevAvailable,
  isNextAvailable,
  handlePagination,
  handleSubNavigation,
  isLastPage = false,
}) => {
  const location = useLocation();
  const [formValues, setFormValues] = useState<any>(
    location.state.initialValues
  );
  const [allApplicantFields, setAllApplicantFields] = useState<any>({});
  const [triggerValidation, setTriggerValidation] = useState({});
  const [showMailAddress, setShowMailAddress] = useState(true);

  const triggerValidationForAllValues = () => {
    var trObj: any = {};
    Object.keys(formValues).forEach((key: string) => {
      if (key.endsWith(`_${applicantNo}`)) {
        trObj[key] = true;
      }
    });
    setTriggerValidation(trObj);
  };

  useEffect(() => {
    const applicantFormInStorage: any = JSON.parse(
      localStorage.getItem(`individual_applicant_${applicantNo}`) ?? "{}"
    );
    Object.keys(applicantFormInStorage).forEach((key: string) => {
      if (key.endsWith(`_${applicantNo}`)) {
        formValues[key] = applicantFormInStorage[key];
      }
    });
    const applicantDetailsFormFields = getApplicantValidations(
      applicantNo.toString()
    );

    Object.entries(applicantDetailsFormFields).forEach((item: any) => {
      if (item[1].required) {
        if (
          formValues[item[0]] == undefined ||
          formValues[item[0]].toString().trim() == ""
        ) {
          setAllApplicantFields((prev: any) => ({ ...prev, [item[0]]: false }));
          formValues[item[0]] = "";
        } else {
          setAllApplicantFields((prev: any) => ({ ...prev, [item[0]]: true }));
        }
      }
    });
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (validateSchema) {
      triggerValidationForAllValues();
    }
  }, [validateSchema]);

  const handleNextClick = () => {
    localStorage.setItem(
      `individual_applicant_${applicantNo}`,
      JSON.stringify(formValues)
    );
    triggerValidationForAllValues();
    if (!getApplicantFormValidationStatus()) {
      return;
    }
    handleSubNavigation(formValues);
  };

  const handlePreviousClick = (isNext: boolean) => {
    localStorage.setItem(
      `individual_applicant_${applicantNo}`,
      JSON.stringify(formValues)
    );
    handlePagination(isNext);
  };

  const scrollToElement = (elementName: string) => {
    const element = document.getElementsByName(elementName)[0];
    if (element != undefined) {
      window.scrollTo({
        top: element.getBoundingClientRect().top + window.pageYOffset - 60,
        behavior: "smooth",
      });
    }
  };

  const getApplicantFormValidationStatus = () => {
    var elementInView = false;
    var formStatus = true;
    Object.entries(allApplicantFields).forEach((item: any) => {
      if (!item[1]) {
        if (!elementInView) {
          scrollToElement(item[0]);
        }
        elementInView = true;
      }
      formStatus = formStatus && item[1];
    });
    return formStatus;
  };

  const handleInputChange = (target: any) => {
    var field = target.name;
    var trigObj: any = {};
    trigObj[field] = false;
    if (
      target.error ||
      target.value == undefined ||
      target.value.toString().trim() == ""
    ) {
      setAllApplicantFields((prev: any) => ({ ...prev, [field]: false }));
      setTriggerValidation(trigObj);
    } else {
      setAllApplicantFields((prev: any) => ({ ...prev, [field]: true }));
      trigObj[field] = true;
      setTriggerValidation(trigObj);
    }
    setFormValues({ ...formValues, [target.name]: target.value });
  };

  const handleAddrCheckBox = (target: any) => {
    const checked = target.checked;
    setAllApplicantFields((prev: any) => ({ ...prev, [target.name]: true }));
    setShowMailAddress(!checked);
    const address_fields = [
      "address_",
      "city_",
      "state_",
      "sc_",
      "postal_code_",
      "county_",
      "country_",
      "is_bus_addr_",
    ];
    if (checked) {
      let tempFormValues = { ...formValues };
      address_fields.forEach((field) => {
        tempFormValues = {
          ...tempFormValues,
          [`mail_${field}${applicantNo}`]: formValues[`${field}${applicantNo}`],
        };
      });
      setFormValues({
        ...tempFormValues,
      });
      let tempAllApplicantFields = { ...allApplicantFields };
      address_fields.forEach((field) => {
        tempAllApplicantFields = {
          ...tempAllApplicantFields,
          [`mail_${field}${applicantNo}`]: true,
        };
      });
      setAllApplicantFields({
        ...tempAllApplicantFields,
      });
      var trigObj: any = {};
      address_fields.forEach((field) => {
        trigObj = {
          ...trigObj,
          [`mail_${field}${applicantNo}`]: true,
        };
      });
      setTriggerValidation(trigObj);
    }
  };

  return (
    <div className={style["form-wrapper"]}>
      <div className={style["form-header"]}>
        <div className={style["form-box-wrapper"]}>
          <div className={style["form-sub-heading"]}>Applicant Information</div>
          <div className={style["form-sub-section"]}>
            <FormInput
              label={"Applicant First Name"}
              name={`first_name_${applicantNo}`}
              value={
                formValues[`first_name_${applicantNo}`]
                  ? formValues[`first_name_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Applicant Last Name"}
              name={`last_name_${applicantNo}`}
              value={
                formValues[`last_name_${applicantNo}`]
                  ? formValues[`last_name_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Email"}
              name={`email_${applicantNo}`}
              value={
                formValues[`email_${applicantNo}`]
                  ? formValues[`email_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Home Phone"}
              name={`phone_${applicantNo}`}
              value={formValues[`phone_${applicantNo}`]}
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Date Of Birth"}
              name={`date_of_birth_${applicantNo}`}
              value={
                formValues[`date_of_birth_${applicantNo}`]
                  ? formValues[`date_of_birth_${applicantNo}`]
                  : ""
              }
              type="date"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Driver's License ID"}
              name={`license_${applicantNo}`}
              value={formValues[`license_${applicantNo}`]}
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"SSN"}
              name={`ssn_${applicantNo}`}
              value={formValues[`ssn_${applicantNo}`]}
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Bank Account Number"}
              name={`bank_account_number_${applicantNo}`}
              value={formValues[`bank_account_number_${applicantNo}`]}
              type="number"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Bank Routing Number"}
              name={`bank_routing_number_${applicantNo}`}
              value={formValues[`bank_routing_number_${applicantNo}`]}
              type="number"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
          </div>
          <div className={style["form-sub-heading"]}>
            Current Address Information
          </div>
          <div className={style["form-sub-section"]}>
            <Address
              applicantNo={applicantNo}
              triggerValidation={triggerValidation}
              formValues={formValues}
              handleInputChange={handleInputChange}
            />
            <RadioInput
              key={`residenceType_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={"Residence type"}
              name={`residenceType_${applicantNo}`}
              options={["Own", "Rent", "Other"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`residenceType_${applicantNo}`]}
            />
            <FormInput
              label={"Years at Present Address"}
              name={`yearsAtResidence_${applicantNo}`}
              value={
                formValues[`yearsAtResidence_${applicantNo}`]
                  ? formValues[`yearsAtResidence_${applicantNo}`]
                  : 0
              }
              type="number"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <CheckBoxInput
              label={"Mail Address same as Current Address?"}
              name={`same_address_${applicantNo}`}
              value={
                formValues[`same_address_${applicantNo}`]
                  ? formValues[`same_address_${applicantNo}`]
                  : 0
              }
              handleInputChange={handleAddrCheckBox}
              triggerValidation={triggerValidation}
            />
          </div>

          {showMailAddress && (
            <>
              <div className={style["form-sub-heading"]}>
                Mail Address Information
              </div>
              <div className={style["form-sub-section"]}>
                <Address
                  applicantNo={applicantNo}
                  triggerValidation={triggerValidation}
                  formValues={formValues}
                  handleInputChange={handleInputChange}
                  prefix="mail_"
                />
              </div>
            </>
          )}

          <div className={style["form-sub-heading"]}>
            Relatives and Dependants Information
          </div>
          <div className={style["form-sub-section"]}>
            <RadioInput
              key={`marital_status_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={
                "Marital Status (Complete for secured or joint loans only)"
              }
              name={`marital_status_${applicantNo}`}
              options={["Married", "Separated", "Unmarried"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`marital_status_${applicantNo}`]}
            />
            <FormInput
              label={"Number of Dependents"}
              name={`dependents_${applicantNo}`}
              type={"number"}
              value={
                formValues[`dependents_${applicantNo}`]
                  ? formValues[`dependents_${applicantNo}`]
                  : 0
              }
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            {formValues[`dependents_${applicantNo}`] > 0 && (
              <>
                <FormInput
                  label={"Ages of Dependents"}
                  name={`dependent_ages_${applicantNo}`}
                  value={
                    formValues[`dependent_ages_${applicantNo}`]
                      ? formValues[`dependent_ages_${applicantNo}`]
                      : ""
                  }
                  type="text"
                  handleInputChange={handleInputChange}
                  triggerValidation={triggerValidation}
                />
                <FormInput
                  label={"Relationship"}
                  name={`relative_relationship_${applicantNo}`}
                  value={
                    formValues[`relative_relationship_${applicantNo}`]
                      ? formValues[`relative_relationship_${applicantNo}`]
                      : ""
                  }
                  type="text"
                  handleInputChange={handleInputChange}
                  triggerValidation={triggerValidation}
                />
              </>
            )}

            <FormInput
              label={"Home Phone"}
              name={`relative_phone_${applicantNo}`}
              value={
                formValues[`relative_phone_${applicantNo}`]
                  ? formValues[`relative_phone_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Nearest Relative First Name"}
              name={`rel_first_name_${applicantNo}`}
              value={
                formValues[`rel_first_name_${applicantNo}`]
                  ? formValues[`rel_first_name_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Nearest Relative Last Name"}
              name={`rel_last_name_${applicantNo}`}
              value={
                formValues[`rel_last_name_${applicantNo}`]
                  ? formValues[`rel_last_name_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
          </div>

          <div className={style["form-sub-heading"]}>Employment Details</div>
          <div className={style["form-sub-section"]}>
            <FormInput
              label={"Current Employment"}
              name={`current_employment_${applicantNo}`}
              value={
                formValues[`current_employment_${applicantNo}`]
                  ? formValues[`current_employment_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Address"}
              name={`employment_address_${applicantNo}`}
              value={
                formValues[`employment_address_${applicantNo}`]
                  ? formValues[`employment_address_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Business Phone"}
              name={`businessPhone_${applicantNo}`}
              value={
                formValues[`businessPhone_${applicantNo}`]
                  ? formValues[`businessPhone_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Position/Title"}
              name={`employment_title_${applicantNo}`}
              value={
                formValues[`employment_title_${applicantNo}`]
                  ? formValues[`employment_title_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Years on the Job"}
              name={`employment_years_${applicantNo}`}
              value={formValues[`employment_years_${applicantNo}`]}
              type="number"
              triggerValidation={triggerValidation}
              handleInputChange={(target: any) => {
                handleInputChange(target);
              }}
            />
            <FormInput
              label={"Years Employed in Profession"}
              name={`profession_years_${applicantNo}`}
              value={formValues[`profession_years_${applicantNo}`]}
              type="number"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Type of Business"}
              name={`business_type_${applicantNo}`}
              value={
                formValues[`business_type_${applicantNo}`]
                  ? formValues[`business_type_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Wages, Salary, Commissions"}
              name={`wages_${applicantNo}`}
              value={
                formValues[`wages_${applicantNo}`]
                  ? formValues[`wages_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <SelectInput
              handleInputChange={handleInputChange}
              label="How Often Paid"
              name={`payment_frequency_${applicantNo}`}
              options={["Bi-Weekly", "Semi-Monthly", "Monthly"]}
              triggerValidation={triggerValidation}
              selectedOption={formValues[`payment_frequency_${applicantNo}`]}
            />
            <FormInput
              label={"Last Paydate"}
              name={`last_paydate_${applicantNo}`}
              value={
                formValues[`last_paydate_${applicantNo}`]
                  ? formValues[`last_paydate_${applicantNo}`]
                  : ""
              }
              type="date"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Next Paydate"}
              name={`next_paydate_${applicantNo}`}
              value={
                formValues[`next_paydate_${applicantNo}`]
                  ? formValues[`next_paydate_${applicantNo}`]
                  : ""
              }
              type="date"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <RadioInput
              key={`self_employed_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={"Self Employed"}
              name={`self_employed_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`self_employed_${applicantNo}`]}
            />
          </div>

          {formValues[`employment_years_${applicantNo}`] < 2 && (
            <>
              <div className={style["form-sub-heading"]}>
                Previous Employment Details
              </div>
              <div className={style["form-sub-section"]}>
                <FormInput
                  label={"Employer/School"}
                  name={`former_employment_${applicantNo}`}
                  value={
                    formValues[`former_employment_${applicantNo}`]
                      ? formValues[`former_employment_${applicantNo}`]
                      : ""
                  }
                  type="text"
                  handleInputChange={handleInputChange}
                  triggerValidation={triggerValidation}
                />
                <FormInput
                  label={"Position/Title"}
                  name={`former_employment_title_${applicantNo}`}
                  value={
                    formValues[`former_employment_title_${applicantNo}`]
                      ? formValues[`former_employment_title_${applicantNo}`]
                      : ""
                  }
                  type="text"
                  handleInputChange={handleInputChange}
                  triggerValidation={triggerValidation}
                />
                <FormInput
                  label={"Type of Business"}
                  name={`former_business_type_${applicantNo}`}
                  value={
                    formValues[`former_business_type_${applicantNo}`]
                      ? formValues[`former_business_type_${applicantNo}`]
                      : ""
                  }
                  type="text"
                  handleInputChange={handleInputChange}
                  triggerValidation={triggerValidation}
                />
                <FormInput
                  label={"Dates From"}
                  name={`from_employment_date_${applicantNo}`}
                  value={
                    formValues[`from_employment_date_${applicantNo}`]
                      ? formValues[`from_employment_date_${applicantNo}`]
                      : ""
                  }
                  type="date"
                  handleInputChange={handleInputChange}
                  triggerValidation={triggerValidation}
                />
                <FormInput
                  label={"Dates To"}
                  name={`to_employment_date_${applicantNo}`}
                  value={
                    formValues[`to_employment_date_${applicantNo}`]
                      ? formValues[`to_employment_date_${applicantNo}`]
                      : ""
                  }
                  type="date"
                  handleInputChange={handleInputChange}
                  triggerValidation={triggerValidation}
                />
                <FormInput
                  label={"Monthly Income"}
                  name={`former_monthly_income_${applicantNo}`}
                  value={
                    formValues[`former_monthly_income_${applicantNo}`]
                      ? formValues[`former_monthly_income_${applicantNo}`]
                      : ""
                  }
                  type="text"
                  handleInputChange={handleInputChange}
                  triggerValidation={triggerValidation}
                />
              </div>
            </>
          )}

          <div className={style["form-sub-heading"]}>
            Additional Information
          </div>
          <div className={style["form-sub-section"]}>
            <RadioInput
              key={`is_us_citizen_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={"Are you a U.S. citizen?"}
              name={`is_us_citizen_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`is_us_citizen_${applicantNo}`]}
            />
            {formValues[`is_us_citizen_${applicantNo}`] == "No" && (
              <>
                <RadioInput
                  key={`is_resident_alien_${applicantNo}`}
                  handleInputChange={handleInputChange}
                  label={"Are you a resident alien?"}
                  name={`is_resident_alien_${applicantNo}`}
                  options={["Yes", "No"]}
                  triggerValidation={triggerValidation}
                  selectedValue={formValues[`is_resident_alien_${applicantNo}`]}
                />
                <RadioInput
                  key={`is_non_resident_alien_${applicantNo}`}
                  handleInputChange={handleInputChange}
                  label={"Are you a non-resident alien?"}
                  name={`is_non_resident_alien_${applicantNo}`}
                  options={["Yes", "No"]}
                  triggerValidation={triggerValidation}
                  selectedValue={
                    formValues[`is_non_resident_alien_${applicantNo}`]
                  }
                />
              </>
            )}
          </div>

          <div className={style["form-sub-heading"]}>
            If your answer to any of the following questions is "Yes", please
            explain in the Additional Comments section.
          </div>
          <div className={style["form-sub-section"]}>
            <RadioInput
              key={`has_outstanding_judgements_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={"Are there any outstanding judgments against you?"}
              name={`has_outstanding_judgements_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={
                formValues[`has_outstanding_judgements_${applicantNo}`]
              }
            />
            <RadioInput
              key={`is_bankrupt_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={
                "Have you been declared bankrupt within the past 10 years?"
              }
              name={`is_bankrupt_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`is_bankrupt_${applicantNo}`]}
            />
            <RadioInput
              key={`property_foreclosure_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={
                "Have you had property foreclosed upon or given title or deed in lieu thereof in the last 7 years?"
              }
              name={`property_foreclosure_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`property_foreclosure_${applicantNo}`]}
            />
            <RadioInput
              key={`is_lawsuit_party_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={"Are you a party to a law suit?"}
              name={`is_lawsuit_party_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`is_lawsuit_party_${applicantNo}`]}
            />
            <RadioInput
              key={`is_paying_maintenance_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={
                "Are you obligated to pay alimony, child support, or separate maintenance?"
              }
              name={`is_paying_maintenance_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`is_paying_maintenance_${applicantNo}`]}
            />
            <RadioInput
              key={`is_note_endorser_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={"Are you a co-maker or endorser on a note?"}
              name={`is_note_endorser_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`is_note_endorser_${applicantNo}`]}
            />
            <RadioInput
              key={`past_due_obligations_${applicantNo}`}
              handleInputChange={handleInputChange}
              label={
                "Do you have any past due obligations owed to or insured by any agency of the federal government?"
              }
              name={`past_due_obligations_${applicantNo}`}
              options={["Yes", "No"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`past_due_obligations_${applicantNo}`]}
            />
            <FormInput
              label={"Additional Comments"}
              name={`additional_comments_${applicantNo}`}
              value={
                formValues[`additional_comments_${applicantNo}`]
                  ? formValues[`monthly_income_${applicantNo}`]
                  : ""
              }
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
          </div>

          <div className={style["form-sub-heading"]}>
            Monthly income and combined expenses information
          </div>
          <div className={style["form-sub-section"]}>
            <FormInput
              label={"Gross Monthly Income"}
              name={`monthly_income_${applicantNo}`}
              value={
                formValues[`monthly_income_${applicantNo}`]
                  ? formValues[`monthly_income_${applicantNo}`]
                  : ""
              }
              type="number"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Monthly Housing Expenses"}
              name={`monthly_expenses_${applicantNo}`}
              value={
                formValues[`monthly_expenses_${applicantNo}`]
                  ? formValues[`monthly_expenses_${applicantNo}`]
                  : ""
              }
              type="number"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
          </div>
        </div>
        <div
          className={`${
            isPrevAvailable
              ? style["form-buttons-wrapper"]
              : style["form-buttons-wrapper"] + " " + style["single-button"]
          }`}
        >
          {isPrevAvailable && (
            <a
              onClick={() => {
                handlePreviousClick(false);
              }}
              className={style.secondary}
            >
              Previous
            </a>
          )}
          {isNextAvailable && (
            <a
              onClick={() => {
                handleNextClick();
              }}
            >
              Next
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicantDetailsForm;
