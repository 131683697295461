import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoanDetailsForm from "../components/loan-details";
import ApplicantDetailsForm from "../components/applicant-details";
import AssetAndDebtForm from "../components/asset-and-debts";
import style from "../../../style.module.css";
import BreadCrumps from "../../../containers/breadcrumps/breadcrumps";
import ApplicationService from "../../../services/application-service";
import { getApplicantValidations } from "../../../common/validations/applicant-fields";
import Loader from "../../loader/loader-component";

const IndividualApplicationForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formValues, setFormValues] = useState<any>(
    location.state.initialValues
  );
  const [loanId, setLoanId] = useState<any>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [sectionIndex, setSectionIndex] = useState<number>(0);
  const [formsValidStatus, setFormsValidStatus] = useState<Array<boolean>>([]);
  const [triggerApplicantValidation, setTriggerApplicantValidation] =
    useState<boolean>(false);
  const [triggerAssetsValidation, setTriggerAssetsValidation] =
    useState<boolean>(false);
  const [triggerLoanValidation, setTriggerLoanValidation] =
    useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");
  const [sectionTitles] = useState<Array<string>>([
    "Primary Applicant Details",
    "Loan Details",
    "Asset and Debt Info",
  ]);

  const handlePagination = (isNext: boolean) => {
    window.scrollTo({ top: 0 });
    if (isNext) setSectionIndex(sectionIndex + 1);
    else setSectionIndex(sectionIndex - 1);
  };

  const handleSubNavigation = (
    sectionFormValues: any,
    isFinalPageSubmit: boolean = false
  ) => {
    const allStatus = [...formsValidStatus, true].slice(-3);
    setFormsValidStatus(allStatus);
    for (let key in formValues) {
      if (key in sectionFormValues) {
        formValues[key] = sectionFormValues[key];
      }
    }
    const applicantNo = 1;
    const applicantFormInStorage: any = JSON.parse(
      localStorage.getItem(`individual_applicant_${applicantNo}`) ?? "{}"
    );
    Object.keys(applicantFormInStorage).forEach((key: string) => {
      if (key.endsWith(`_${applicantNo}`)) {
        formValues[key] = applicantFormInStorage[key];
      }
    });
    const loanFormInStorage: any = JSON.parse(
      localStorage.getItem(`loan_applicant_${applicantNo}`) ?? "{}"
    );

    Object.keys(loanFormInStorage).forEach((key: any) => {
      formValues[key] = loanFormInStorage[key];
    });
    const result: { [key: string]: any } = {};
    const keys = Object.keys(getApplicantValidations());
    for (const key of keys) {
      if (formValues.hasOwnProperty(key)) {
        const newKey = key.replace(/_\d+$/, "");
        result[newKey] = formValues[key];
      }
    }
    setFormValues((prev: any) => ({ ...prev, applicants: [result] }));

    if (!isFinalPageSubmit) {
      handlePagination(true);
    } else {
      handleBaseSubmit(allStatus);
    }
  };

  const sectionList = {
    applicant_details: {
      title: "Primary Applicant Details",
      description: "",
      FC: (
        <ApplicantDetailsForm
          validateSchema={triggerApplicantValidation}
          handlePagination={handlePagination}
          handleSubNavigation={handleSubNavigation}
          isNextAvailable={true}
          isPrevAvailable={false}
          applicantNo={1}
          key={1}
        />
      ),
      applicantIndex: 0,
    },
    loan_details: {
      title: "Loan Details",
      description: "",
      FC: (
        <LoanDetailsForm
          validateSchema={triggerLoanValidation}
          handlePagination={handlePagination}
          handleSubNavigation={handleSubNavigation}
          isNextAvailable={true}
          isPrevAvailable={true}
          applicantNo={1}
          key={2}
        />
      ),
    },
    assets_and_debts: {
      title: "Asset and Debt Info",
      description: "",
      FC: (
        <AssetAndDebtForm
          validateSchema={triggerAssetsValidation}
          handlePagination={handlePagination}
          handleSubNavigation={handleSubNavigation}
          isNextAvailable={true}
          isPrevAvailable={true}
          applicantNo={1}
          isLastPage={true}
          key={3}
        />
      ),
    },
  };
  const sections: Array<string> = Object.keys(sectionList);
  const [currentSection, setCurrentSection] = useState<string>(
    sections[sectionIndex]
  );

  useEffect(() => {
    setCurrentSection(Object.keys(sectionList)[sectionIndex]);
  }, [sectionIndex]);

  const handleSectionChange = (index: number) => {
    setSectionIndex(index);
  };

  const handleBaseSubmit = async (formStatus: any) => {
    setTriggerApplicantValidation(true);
    setTriggerAssetsValidation(true);
    setTriggerLoanValidation(true);
    if (formStatus.length != 3 || formStatus.every((x: any) => x != true)) {
      setFormError("Fill all fields in " + sectionTitles.join(", "));
      return;
    }
    const loadId = (+new Date()).toString();
    setLoanId(loadId);
    let payload = {
      loan_application_id: loadId,
      lead_provider_id: "life_asset",
      campaign_id: "life_asset",
      loan_application_type: "individual",
      request_id: window.crypto.randomUUID(),
      applicants: formValues.applicants,
      assets: formValues.assets,
      debts: formValues.debts,
      requested_amount: +formValues.requested_amount,
      term_requested: +formValues.term_requested,
      payment_date: formValues.payment_date,
      payment_type: formValues.payment_type,
      purpose: formValues.purpose,
      loan_type: formValues.loan_type,
      credit_references: formValues.credit_references,
      additional_names: formValues.additional_names,
      preferred_loan_officer: formValues.preferred_loan_officer,
    };
    setIsSubmitting(true);
    try {
      await ApplicationService.init.submitForm(payload);
      for (let key in localStorage) {
        if (key.includes("_applicant_")) {
          localStorage.removeItem(key);
        }
      }
      setFormStatus(true);
      setIsSubmitting(false);
    } catch {
      setFailed(true);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className={style["top-logo"]}>
        <div className={style["logo-section"]}>
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="3090.000000pt"
            height="40.000000pt"
            viewBox="0 0 3090.000000 1445.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,1445.000000) scale(0.100000,-0.100000)"
              fill="#e63b2f"
              stroke="none"
            >
              <path d="M6403 13326 l-28 -24 -3 -5959 c-2 -4464 1 -5965 9 -5986 26 -62 104 -77 158 -31 l31 26 0 5973 0 5972 -26 27 c-36 35 -101 36 -141 2z" />
              <path d="M3365 10949 c-408 -47 -705 -382 -705 -794 0 -209 70 -390 209 -538 162 -172 356 -257 586 -257 230 0 424 85 586 257 139 148 209 329 209 538 0 139 -23 235 -84 360 -98 197 -279 349 -486 408 -47 14 -224 40 -240 36 -3 -1 -36 -5 -75 -10z" />
              <path d="M5045 9380 c-516 -261 -981 -412 -1431 -465 -150 -18 -457 -20 -594 -5 -318 35 -646 149 -881 306 -99 66 -241 183 -271 224 -17 23 -25 27 -40 19 -17 -9 -16 -13 12 -62 562 -959 668 -2451 295 -4157 -72 -332 -170 -685 -284 -1023 -33 -97 -54 -174 -48 -170 7 4 75 51 151 106 1016 727 1594 891 2261 642 252 -94 542 -271 860 -524 165 -132 153 -125 170 -111 12 10 4 25 -44 88 -304 399 -509 924 -591 1511 -63 456 -51 1033 35 1586 72 465 196 964 360 1445 68 200 188 511 244 634 17 38 30 70 29 71 -2 0 -106 -51 -233 -115z" />
              <path d="M9859 9317 c-90 -26 -169 -102 -199 -192 -21 -62 -16 -156 9 -209 29 -58 90 -119 149 -148 69 -33 178 -32 247 4 64 33 127 100 150 161 23 61 17 175 -13 232 -26 50 -84 107 -139 134 -54 27 -144 35 -204 18z" />
              <path d="M11390 9319 c-127 -19 -234 -73 -315 -160 -104 -111 -125 -214 -132 -656 l-6 -323 -178 0 -179 0 0 -155 0 -155 180 0 180 0 0 -1245 0 -1245 180 0 180 0 0 1245 0 1245 295 0 295 0 0 155 0 155 -296 0 -296 0 4 323 c3 309 5 324 26 368 24 50 75 92 139 115 83 29 264 9 401 -45 l52 -21 0 169 0 169 -107 26 c-182 43 -305 53 -423 35z" />
              <path d="M28360 8700 l0 -520 -245 0 -245 0 0 -155 0 -155 245 0 245 0 0 -1245 0 -1245 180 0 180 0 0 1245 0 1245 285 0 285 0 0 155 0 155 -285 0 -285 0 0 520 0 520 -180 0 -180 0 0 -520z" />
              <path d="M7500 7275 l0 -1895 915 0 915 0 0 185 0 185 -725 0 -725 0 0 1710 0 1710 -190 0 -190 0 0 -1895z" />
              <path d="M17710 7334 c-476 -1010 -877 -1863 -892 -1895 l-27 -59 208 0 207 0 294 620 293 620 821 0 822 0 295 -620 294 -619 202 -1 201 0 -96 208 c-54 114 -450 966 -882 1894 l-785 1688 -45 0 -45 -1 -865 -1835z m1237 344 l321 -683 -646 -3 c-355 -1 -647 -1 -649 1 -4 5 640 1367 647 1367 4 0 151 -307 327 -682z" />
              <path d="M13306 8240 c-329 -38 -575 -152 -799 -369 -253 -245 -408 -549 -452 -888 -63 -480 91 -939 430 -1285 174 -177 385 -292 635 -346 358 -77 746 -38 1033 104 273 134 473 333 641 636 l37 66 -144 76 c-79 42 -148 76 -153 76 -5 0 -34 -40 -64 -88 -70 -111 -195 -266 -255 -316 -127 -105 -300 -190 -480 -237 -78 -20 -114 -24 -270 -24 -157 0 -191 3 -265 23 -195 54 -308 120 -460 272 -86 86 -128 137 -167 204 -95 161 -147 331 -160 519 l-6 97 1258 0 1258 0 -6 108 c-17 286 -99 543 -245 762 -73 110 -239 281 -349 360 -164 117 -370 201 -578 235 -115 19 -337 26 -439 15z m436 -348 c302 -81 526 -245 661 -483 41 -73 135 -325 124 -335 -2 -2 -472 -3 -1044 -2 l-1041 3 32 95 c140 413 412 660 810 736 105 20 359 12 458 -14z" />
              <path d="M21465 8239 c-91 -13 -213 -54 -284 -96 -84 -49 -206 -171 -254 -253 -61 -106 -89 -206 -94 -345 -12 -309 108 -519 412 -720 44 -28 159 -95 256 -147 278 -151 403 -241 463 -336 75 -120 87 -280 29 -402 -90 -190 -265 -300 -480 -300 -186 0 -360 80 -527 244 -32 31 -61 56 -64 55 -4 0 -55 -56 -114 -124 l-108 -123 46 -51 c66 -73 192 -165 297 -216 328 -158 701 -148 961 26 88 58 198 172 250 257 89 147 131 347 108 516 -41 308 -207 489 -687 751 -303 165 -422 260 -486 390 -44 90 -52 201 -20 287 77 202 302 309 537 253 121 -28 258 -105 370 -208 44 -40 59 -49 72 -41 9 6 61 59 117 118 l100 108 -45 40 c-288 251 -573 357 -855 317z" />
              <path d="M23455 8239 c-91 -13 -213 -54 -284 -96 -84 -49 -206 -171 -254 -253 -61 -106 -89 -206 -94 -345 -12 -309 108 -519 412 -720 44 -28 159 -95 256 -147 278 -151 403 -241 463 -336 75 -120 87 -280 29 -402 -90 -190 -265 -300 -480 -300 -186 0 -360 80 -527 244 -32 31 -61 56 -64 55 -4 0 -55 -56 -114 -124 l-108 -123 46 -51 c66 -73 192 -165 297 -216 328 -158 701 -148 961 26 88 58 198 172 250 257 89 147 131 347 108 516 -41 308 -207 489 -687 751 -303 165 -422 260 -486 390 -44 90 -52 201 -20 287 77 202 302 309 537 253 121 -28 258 -105 370 -208 44 -40 59 -49 72 -41 9 6 61 59 117 118 l100 108 -45 40 c-288 251 -573 357 -855 317z" />
              <path d="M26016 8240 c-329 -38 -575 -152 -799 -369 -253 -245 -408 -549 -452 -888 -63 -480 91 -939 430 -1285 174 -177 385 -292 635 -346 358 -77 746 -38 1033 104 273 134 473 333 641 636 l37 66 -144 76 c-79 42 -148 76 -153 76 -5 0 -34 -40 -64 -88 -70 -111 -195 -266 -255 -316 -127 -105 -300 -190 -480 -237 -78 -20 -114 -24 -270 -24 -157 0 -191 3 -265 23 -195 54 -308 120 -460 272 -86 86 -128 137 -167 204 -95 161 -147 331 -160 519 l-6 97 1258 0 1258 0 -6 108 c-17 286 -99 543 -245 762 -73 110 -239 281 -349 360 -164 117 -370 201 -578 235 -115 19 -337 26 -439 15z m436 -348 c302 -81 526 -245 661 -483 41 -73 135 -325 124 -335 -2 -2 -472 -3 -1044 -2 l-1041 3 32 95 c140 413 412 660 810 736 105 20 359 12 458 -14z" />
              <path d="M9760 6780 l0 -1400 180 0 180 0 0 1400 0 1400 -180 0 -180 0 0 -1400z" />
            </g>
          </svg>
        </div>
        <div className={style["header-text-section"]}>
          <div className={style["loan-name-header"]}>Individual Loan</div>
        </div>
      </div>
      <div className={style["max-width-container"]}>
        {!formStatus && (
          <BreadCrumps
            sections={sectionTitles}
            selectedSectionIndex={sectionIndex}
            handleOnClick={(event) => {
              handleSectionChange(event);
            }}
            key={sectionIndex}
          />
        )}
        {!isSubmitting && (
          <div className={style["multi-form-wrap-outer"]}>
            <div className={style["multi-form-wrapper"]}>
              <div className={style["section"]}>
                <div className={style["form-wrapper"]}>
                  <div className={style["form-header"]}>
                    <span>
                      {
                        sectionList[currentSection as keyof typeof sectionList]
                          .description
                      }
                    </span>
                  </div>

                  {!formStatus && !failed && (
                    <>
                      <>
                        {currentSection === sections[0] && <></>}
                        {
                          sectionList[
                            currentSection as keyof typeof sectionList
                          ].FC
                        }
                      </>
                      {!(formError == "") && (
                        <div className={style["form-label-error"]}>
                          {" "}
                          {formError}
                        </div>
                      )}
                    </>
                  )}
                  {formStatus && (
                    <>
                      <div className={style["form-submit-response-outer"]}>
                        <div className={style["form-submit-response-wrapper"]}>
                          <div className={style["svg-holder"]}>
                            <svg
                              fill="green"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" />
                            </svg>
                          </div>
                          <div className={style["loan-id-wrapper"]}>
                            <div className={style["loan-text"]}>
                              Loan Appplication ID:
                            </div>
                            <div className={style["loan-id"]}>
                              <span className={style["loan-id-text"]}>
                                {loanId}
                              </span>
                            </div>
                          </div>
                          <p>
                            Thank You for your application.
                            <br /> A Loan Officer will be in touch with you
                            shortly. In the meantime, you if you have any
                            questions or concerns, please feel free to reach us
                            at <strong>loans@lifeasset.com</strong> or
                            <strong> 844-LifeAsset</strong>
                          </p>
                          <br />
                        </div>
                      </div>
                    </>
                  )}
                  {failed && (
                    <div className={style["form-submit-response-outer"]}>
                      <div className={style["form-submit-response-wrapper"]}>
                        <div className={style["svg-holder"]}>
                          <svg
                            fill="red"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" />
                          </svg>
                        </div>
                        <div className={style["loan-id-wrapper"]}>
                          <div className={style["loan-text"]}>
                            Loan Appplication request failed, Please retry!
                          </div>
                        </div>
                        <div className={style["form-buttons-wrapper"]}>
                          <a
                            onClick={() => {
                              navigate("/dashboard");
                            }}
                          >
                            Back to home
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Loader isLoading={isSubmitting && !formStatus} />
    </>
  );
};

export default IndividualApplicationForm;
