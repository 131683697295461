import style from "../../style.module.css";

interface Props {
  isLoading: boolean;
}

const Loader: React.FC<Props> = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className={style["multi-form-wrap-outer"]}>
          <div className={style["multi-form-wrapper"]}>
            <div className={`${style["form-wrapper"]} ${style["loading"]}`}>
              <div className={style["loader-wrapper"]}>
                <div className={style["lds-roller"]}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
