import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

export function generateRandomString() {
  var array = new Uint32Array(28);
  window.crypto.getRandomValues(array);
  const code = Array.from(array, (dec) =>
    ("0" + dec.toString(16)).substr(-2)
  ).join("");
  return code;
}

export async function generateChallenge(verifier: any) {
  var hashed = await sha256(verifier);
  return base64urlencode(hashed);
}

export function parseQueryString(unparsedString: string) {
  if (unparsedString === "") {
    return {};
  }
  var segments = unparsedString.split("&").map((s) => s.split("="));
  var queryString: any = {};
  segments.forEach((s) => (queryString[s[0]] = s[1]));
  return queryString;
}

function sha256(plain: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(str: ArrayBuffer) {
  var enc = new Uint8Array(str);
  return btoa(String.fromCharCode.apply(null, Array.from(enc)))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

export function isTokenExpired(token: string) {
  if (token === "null") return true;
  var decoded: any = jwt_decode(token);
  if (Date.now() >= decoded.exp * 1000) {
    localStorage.removeItem("access_token");
    return true;
  }
  return false;
}

export function displayToast(type: string, message: string) {
  switch (type) {
    case "success":
      toast.success(message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    case "error":
      toast.error(message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  }
}
