import React, { useEffect, useState } from "react";
import style from "../../../style.module.css";
import ErrorLabel from "../error-label/error-control";

type RadioInputProps = {
  label: string;
  name: string;
  options: Array<string>;
  handleInputChange: (event: any) => any;
  triggerValidation: any;
  selectedValue: any;
};

const RadioInput: React.FC<RadioInputProps> = ({
  label,
  name,
  options,
  handleInputChange,
  triggerValidation,
  selectedValue
}) => {
  const [checkedValue, setCheckedValue] = useState<any>("");
  const [error, setError] = useState<boolean>(false);
  const [fieldStatus, setFieldStatus] = useState<boolean>(false);

  useEffect(()=>{
    if(selectedValue){
      setCheckedValue(selectedValue)
      const value = selectedValue
      handleInputChange({ name, value });
    }
  }, [selectedValue])

  useEffect(() => {
    if (
      triggerValidation &&
      Object.keys(triggerValidation).length &&
      triggerValidation[name] != undefined &&
      triggerValidation[name]
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [triggerValidation]);

  const handleChange =
    (value: any, name: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCheckedValue(value);
      handleInputChange({ name, value });
    };

  return (
    <div className={`${style["form-box"]} ${style["full-width"]}`} key={name}>
      <div className={style["form-label"]}>{label}</div>
      <div className={style["input-box"]}>
        {options.map((item, index) => (
          <React.Fragment key={index}>
            <label className={style["radio-container"]}>
              <input
                type="radio"
                name={name}
                value={item}
                checked={item == selectedValue}
                onChange={handleChange(item, name)}
              />
              <span className={style["checkmark"]}></span>
              <span className={style["radio-text"]}>{item}</span>
            </label>
          </React.Fragment>
        ))}
      </div>
      {error && (
        <ErrorLabel
          name={name}
          value={checkedValue}
          required={true}
          message={`${label} is required`}
          setFieldValid={setFieldStatus}
        />
      )}
    </div>
  );
};

export default RadioInput;
