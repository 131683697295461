import axios from "axios";

interface UserInfo {
  id: string;
  address: any;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

class ApplicationService {
  private static _instance: ApplicationService;
  public cancelToken: any;
  private constructor() {}
  public static get init() {
    if (this._instance) {
      return this._instance;
    }
    this._instance = new this();
    return this._instance;
  }

  public submitForm = async (payload: any): Promise<void> => {
    await axios.post(
      process.env.REACT_APP_XRV3_BASE_URL + "/evaluate-loan-application",
      payload,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_XRV3_API_KEY,
          "Content-Type": "application/json",
          auth_key: "Bearer " + window.localStorage.getItem("access_token"),
        },
      }
    );
    console.log("Form submitted successfully");
  };

  public async getFormData(): Promise<any> {
    let response: UserInfo = JSON.parse(
      window.localStorage.getItem("user_info") + ""
    );
    response.address = response.address.streetAddress;
    return {
      customer_id: response.id,
      first_name_1: response.firstName,
      last_name_1: response.lastName,
      email_1: response.email,
      phone_1: response.phoneNumber,
      address_1: response.address,
      requested_amount: 100,
      term_requested: 0,
      payment_date: new Date().toISOString().substring(0, 10),
      payment_type: "",
      purpose: "",
      credit_references: "",
      additional_names: "",
      assets: [],
      debts: [],
      applicants: [],
      ssn_1: "",
      preferred_loan_officer: "",
    };
  }

  public async getPreviousApplications(): Promise<any> {
    try {
      const user_info: any = JSON.parse(
        window.localStorage.getItem("user_info") || ""
      );
      const id = user_info["id"];
      const config = {
        "x-api-key": process.env.REACT_APP_API_KEY,
        Accept: "application/json",
        auth_key: "Bearer " + window.localStorage.getItem("access_token"),
      };
      let response = await axios.get(
        process.env.REACT_APP_XRV3_BASE_URL + `/borrowers/${id}/applications`,
        { headers: config }
      );
      return response.data.data["reports"];
    } catch (error) {
      console.error(error);
      return [];
    }
  }
}

export default ApplicationService;
