import React, { useEffect, useState } from "react";
import style from "../../../style.module.css";
import ErrorLabel from "../error-label/error-control";

type FormInputProps = {
  label?: string;
  name?: string;
  value?: any;
  type?: string;
  isHidden?: boolean;
  handleInputChange?: (event: any) => any;
  triggerValidation?: any;
};

const FormInput: React.FC<FormInputProps> = ({
  label = "",
  name = "",
  value = "",
  type = "text",
  handleInputChange = null,
  isHidden = false,
  triggerValidation = null,
}) => {
  const [inputValue, setInputValue] = useState<any>(value);
  const [fieldStatus, setFieldStatus] = useState<boolean>(false);
  const [fielderror, setError] = useState<boolean>(false);

  useEffect(() => {
    setInputValue(setCustomValues(value, name));
  }, [value]);

  useEffect(() => {
    setError(checkFieldError());
  }, [triggerValidation]);

  const checkFieldError = () => {
    if (
      triggerValidation &&
      Object.keys(triggerValidation).length &&
      triggerValidation[name] != undefined &&
      triggerValidation[name]
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const { name, value } = event.target;
    const customValue = setCustomValues(newValue, name);
    setInputValue(customValue);
    handleInputChange && handleInputChange({ name, value, fieldStatus });
  };

  const setCustomValues = (value: any, name: string) => {
    switch (true) {
      case name.toLowerCase().includes("ssn"): {
        var val = value.replace(/\D/g, "");
        var newVal = "";
        if (val.length > 4) {
          value = val;
        }
        if (val.length > 3 && val.length < 6) {
          newVal += val.substr(0, 3) + "-";
          val = val.substr(3);
        }
        if (val.length > 5) {
          newVal += val.substr(0, 3) + "-";
          newVal += val.substr(3, 2) + "-";
          val = val.substr(5);
        }
        newVal += val;
        value = newVal.substring(0, 11);
        return value;
      }
      case name.toLowerCase().includes("phone"): {
        const cleanNum = value.toString().replace(/\D/g, '').substring(0,10);
        const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
        if (match) {
          return '(' + match[1] + ') ' + (match[2] ? match[2] + "-" : "") + match[3];
        }
        return cleanNum;
      }
      default:
        return value;
    }
  };

  const validateInput = () => {
    setError(true);
  };

  return (
    <>
      {!isHidden && (
        <>
          <div className={style["form-box"]}>
            <div className={style["form-label"]}>{label}</div>
            <div className={style["input-box"]}>
              <input
                type={type}
                value={inputValue}
                name={name}
                min={0}
                onChange={handleChange}
                onBlur={() => {
                  validateInput();
                }}
              />
            </div>
            {fielderror && (
              <ErrorLabel
                name={name}
                value={inputValue}
                required={true}
                message={`${label} is required`}
                setFieldValid={setFieldStatus}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FormInput;
