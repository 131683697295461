export const getApplicantValidations = (n: string = "1") => {
  return {
    [`first_name_${n}`]: {
      required: true,
      name: "first_name",
      message: "First Name",
      validation: /^[a-z ,.'-]+$/i,
    },
    [`last_name_${n}`]: {
      required: true,
      name: "last_name",
      message: "Last Name",
      validation: /^[a-z ,.'-]+$/i,
    },
    [`email_${n}`]: {
      required: true,
      name: "email",
      message: "Email",
      validation: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
    },
    [`ssn_${n}`]: {
      required: true,
      name: "SSN",
      message: "SSN",
      validation:
        /^(?!666|000|9[0-9]{2})[0-9]{3}-(?!00)[0-9]{2}-(?!0{4})[0-9]{4}$/,
    },
    [`phone_${n}`]: {
      required: true,
      name: "phone",
      message: "Phone Number",
      validation:
        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    },
    [`date_of_birth_${n}`]: {
      required: true,
      name: "date_of_birth",
      message: "Date of Birth",
      validation: null,
    },
    [`bank_account_number_${n}`]: {
      required: true,
      name: "bank_account_number",
      message: "Bank Account Number",
      validation: null,
    },
    [`bank_routing_number_${n}`]: {
      required: true,
      name: "bank_routing_number",
      message: "Bank Routing Number",
      validation: null,
    },
    [`businessPhone_${n}`]: {
      required: false,
      name: "businessPhone",
      message: "Business Phone",
      validation:
        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    },
    [`address_${n}`]: {
      required: true,
      name: "address",
      message: "Address",
      validation: null,
    },
    [`city_${n}`]: {
      required: true,
      name: "city",
      message: "City",
      validation: null,
    },
    [`state_${n}`]: {
      required: true,
      name: "state",
      message: "State",
      validation: null,
    },
    [`sc_${n}`]: {
      required: true,
      name: "state_code",
      message: "State Code",
      validation: null,
    },
    [`postal_code_${n}`]: {
      required: true,
      name: "postal_code",
      message: "Postal Code",
      validation: null,
    },
    [`county_${n}`]: {
      required: true,
      name: "county",
      message: "County",
      validation: null,
    },
    [`country_${n}`]: {
      required: true,
      name: "country",
      message: "Country",
      validation: null,
    },
    [`is_bus_addr_${n}`]: {
      required: true,
      name: "is_business_address",
      message: "Business Address",
      validation: null,
    },
    [`mail_address_${n}`]: {
      required: true,
      name: "mail_address",
      message: "Mail Address",
      validation: null,
    },
    [`mail_city_${n}`]: {
      required: true,
      name: "mail_city",
      message: "Mail City",
      validation: null,
    },
    [`mail_state_${n}`]: {
      required: true,
      name: "mail_state",
      message: "Mail State",
      validation: null,
    },
    [`mail_sc_${n}`]: {
      required: true,
      name: "mail_state_code",
      message: "Mail State Code",
      validation: null,
    },
    [`mail_postal_code_${n}`]: {
      required: true,
      name: "mail_postal_code",
      message: "Mail Postal Code",
      validation: null,
    },
    [`mail_county_${n}`]: {
      required: true,
      name: "mail_county",
      message: "Mail County",
      validation: null,
    },
    [`mail_country_${n}`]: {
      required: true,
      name: "mail_country",
      message: "Mail Country",
      validation: null,
    },
    [`mail_is_bus_addr_${n}`]: {
      required: true,
      name: "mail_is_business_address",
      message: "Mail Business Address",
      validation: null,
    },
    [`license_${n}`]: {
      required: true,
      name: "license",
      message: "Driver's License ID",
      validation: null,
    },
    [`yearsAtResidence_${n}`]: {
      required: true,
      name: "yearsAtResidence",
      message: "Years at Present Address",
      validation: null,
    },
    [`residenceType_${n}`]: {
      required: true,
      name: "residenceType",
      message: "Residence type",
      validation: null,
    },
    [`formerAddress_${n}`]: {
      required: false,
      name: "formerAddress",
      message: "Former Address",
      validation: null,
    },
    [`yearsAtFormerAddress_${n}`]: {
      required: false,
      name: "yearsAtFormerAddress",
      message: null,
      validation: null,
    },
    [`previousResidenceType_${n}`]: {
      required: false,
      name: "previousResidenceType",
      message: null,
      validation: null,
    },
    [`marital_status_${n}`]: {
      required: false,
      name: "marital_status",
      message: null,
      validation: null,
    },
    [`dependents_${n}`]: {
      required: false,
      name: "dependents",
      message: null,
      validation: null,
    },
    [`dependent_ages_${n}`]: {
      required: false,
      name: "dependentAges",
      message: null,
      validation: null,
    },
    [`nearest_relative_${n}`]: {
      required: false,
      name: "nearestRelative",
      message: "Nearest Relative Name",
      validation: /^[a-z ,.'-]+$/i,
    },
    [`rel_first_name_${n}`]: {
      required: true,
      name: "rel_first_name",
      message: "Relative First Name",
      validation: /^[a-z ,.'-]+$/i,
    },
    [`rel_last_name_${n}`]: {
      required: true,
      name: "rel_last_name",
      message: "Relative Last Name",
      validation: /^[a-z ,.'-]+$/i,
    },
    [`relative_relationship_${n}`]: {
      required: false,
      name: "relativeRelationship",
      message: "Relative Relationship",
      validation: /^[a-z ,.'-]+$/i,
    },
    [`relative_phone_${n}`]: {
      required: false,
      name: "relativePhone",
      message: "Relative Mobile",
      validation:
        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    },
    [`current_employment_${n}`]: {
      required: true,
      name: "currentEmployment",
      message: "Current Employment",
      validation: /^[a-z ,.'-]+$/i,
    },
    [`employment_address_${n}`]: {
      required: true,
      name: "employmentAddress",
      message: "Employment Address",
      validation: null,
    },
    [`self_employed_${n}`]: {
      required: true,
      name: "isSelfEmployed",
      message: "Self Employed",
      validation: null,
    },
    [`employment_title_${n}`]: {
      required: true,
      name: "employmentTitle",
      message: "Employment Title",
      validation: null,
    },
    [`employment_years_${n}`]: {
      required: true,
      name: "employmentYears",
      message: "Job Experience",
      validation: null,
    },
    [`profession_years_${n}`]: {
      required: false,
      name: "professionYears",
      message: "Profession Experience",
      validation: null,
    },
    [`business_type_${n}`]: {
      required: false,
      name: "businessType",
      message: "Business Type",
      validation: null,
    },
    [`wages_${n}`]: {
      required: true,
      name: "wages",
      message: "Wages",
      validation: /^([0-9]*[.])?[0-9]+$/,
    },
    [`payment_frequency_${n}`]: {
      required: true,
      name: "paymentFrequency",
      message: "Payment Frequency",
      validation: null,
    },
    [`last_paydate_${n}`]: {
      required: true,
      name: "last_paydate",
      message: "Last Paydate",
      validation: null,
    },
    [`next_paydate_${n}`]: {
      required: true,
      name: "next_paydate",
      message: "Next Paydate",
      validation: null,
    },
    [`former_employment_${n}`]: {
      required: false,
      name: "formerEmployment",
      message: "Former Employer",
      validation: /^[a-z ,.'-]+$/i,
    },
    [`former_employment_title_${n}`]: {
      required: false,
      name: "formerEmploymentTitle",
      message: "Former Employment Title",
      validation: null,
    },
    [`former_business_type_${n}`]: {
      required: false,
      name: "formerBusinessType",
      message: "Former Business Type",
      validation: null,
    },
    [`from_employment_date_${n}`]: {
      required: false,
      name: "fromEmploymentDate",
      message: "From Employment Date",
      validation: null,
    },
    [`to_employment_date_${n}`]: {
      required: false,
      name: "toEmploymentDate",
      message: "To Employment Date",
      validation: null,
    },
    [`former_monthly_income_${n}`]: {
      required: false,
      name: "formerMonthlyIncome",
      message: "Former Monthly Income",
      validation: /^([0-9]*[.])?[0-9]+$/,
    },
    [`is_us_citizen_${n}`]: {
      required: true,
      name: "isUsCitizen",
      message: "U.S. citizenship info",
      validation: null,
    },
    [`is_resident_alien_${n}`]: {
      required: false,
      name: "isResidentAlien",
      message: null,
      validation: null,
    },
    [`is_non_resident_alien_${n}`]: {
      required: false,
      name: "isNonResidentAlien",
      message: null,
      validation: null,
    },
    [`has_outstanding_judgements_${n}`]: {
      required: true,
      name: "hasOutstandingJudgements",
      message: "Outstanding judgement info",
      validation: null,
    },
    [`is_bankrupt_${n}`]: {
      required: true,
      name: "isBankrupt",
      message: "Bankruptcy info",
      validation: null,
    },
    [`property_foreclosure_${n}`]: {
      required: true,
      name: "propertyForeclosure",
      message: "Property Foreclosure info",
      validation: null,
    },
    [`is_lawsuit_party_${n}`]: {
      required: true,
      name: "isLawsuitParty",
      message: "Party to Lawsuit info",
      validation: null,
    },
    [`is_paying_maintenance_${n}`]: {
      required: true,
      name: "isPayingMaintenance",
      message: "Maintenance payment info",
      validation: null,
    },
    [`is_note_endorser_${n}`]: {
      required: true,
      name: "isNoteEndorser",
      message: "Note Endorsement info",
      validation: null,
    },
    [`past_due_obligations_${n}`]: {
      required: true,
      name: "hasPastDueObligations",
      message: "Past Due Obligations info",
      validation: null,
    },
    [`monthly_income_${n}`]: {
      required: true,
      name: "monthlyIncome",
      message: "Monthly Income",
      validation: /^([0-9]*[.])?[0-9]+$/,
    },
    [`monthly_expenses_${n}`]: {
      required: true,
      name: "monthlyExpenses",
      message: "Monthly Expenses",
      validation: /^([0-9]*[.])?[0-9]+$/,
    },
  };
};
