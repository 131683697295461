export const LOAN_DETAILS_VALIDATIONS = {
  requested_amount: {
    required: true,
    name: "requestedAmount",
    message: "Amount Requested",
    validation: null,
  },
  term_requested: {
    required: true,
    name: "term_requested",
    message: "Term of Loan",
    validation: null,
  },
  payment_date: {
    required: true,
    name: "payment_date",
    message: "Payment Date",
    validation: null,
  },
  payment_type: {
    required: true,
    name: "payment_type",
    message: "Payment Type",
    validation: null,
  },
  purpose: {
    required: true,
    name: "purpose",
    message: "Loan Purpose",
    validation: /^[a-z ,.'-]+$/i,
  },
  loan_type: {
    required: true,
    name: "loan_type",
    message: "Loan Type",
    validation: null,
  },
};
