import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";

import { isTokenExpired, displayToast } from "../../utils/helpers";
import AuthService from "../../services/auth-service";
import style from "../../style.module.css";

const OTPAuth: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("access_token") + "";
    if (token.length > 0) {
      if (!isTokenExpired(token)) {
        navigate("/dashboard");
      }
    }
  });

  const captchaRef: any = useRef(null);
  const [viewOtpForm, setViewOtpForm] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [isAwaiting, setIsAwaiting] = useState<boolean>(false);

  const loginSubmit = async (event: any) => {
    event.preventDefault();
    const token = captchaRef.current.getValue();
    if (token.length === 0) {
      displayToast("error", "Please Verify the CAPTCHA and try again.");
    } else {
      captchaRef.current.reset();
      try {
        setIsAwaiting(true);
        const res = await AuthService.init.sendOtp(email, token);
        setIsAwaiting(false);
        if (res.status === 200) {
        } else {
          displayToast("error", "Something went wrong. Please try again.");
        }
        displayToast("success", `OTP sent to ${email}.`);
        setViewOtpForm(true);
      } catch (error) {
        displayToast("error", "Something went wrong. Please try again.");
        window.location.reload();
      }
    }
  };

  const verifyOtp = async (event: any) => {
    event.preventDefault();
    try {
      setIsAwaiting(true);
      const res = await AuthService.init.verifyOtp(email, otp);
      setIsAwaiting(false);
      if (res.status === 200) {
        window.localStorage.setItem("access_token", res.data.access_token);
        navigate("/dashboard");
      } else {
        displayToast(
          "error",
          "Invalid OTP. Try again or go back to generate new OTP."
        );
      }
    } catch {
      displayToast("error", "Something went wrong. Please try again.");
      window.location.reload();
    }
  };

  return (
    <div className={`${style["wrapper"]}  ${style["login-page"]}`}>
      <div className={style["top-logo"]}>
        <div className={style["logo-section"]}>
          <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="3090.000000pt"
            height="40.000000pt"
            viewBox="0 0 3090.000000 1445.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g
              transform="translate(0.000000,1445.000000) scale(0.100000,-0.100000)"
              fill="#e63b2f"
              stroke="none"
            >
              <path
                d="M6403 13326 l-28 -24 -3 -5959 c-2 -4464 1 -5965 9 -5986 26 -62 104
-77 158 -31 l31 26 0 5973 0 5972 -26 27 c-36 35 -101 36 -141 2z"
              />
              <path d="M3365 10949 c-408 -47 -705 -382 -705 -794 0 -209 70 -390 209 -538 162 -172 356 -257 586 -257 230 0 424 85 586 257 139 148 209 329 209 538 0 139 -23 235 -84 360 -98 197 -279 349 -486 408 -47 14 -224 40 -240 36 -3 -1 -36 -5 -75 -10z" />
              <path d="M5045 9380 c-516 -261 -981 -412 -1431 -465 -150 -18 -457 -20 -594 -5 -318 35 -646 149 -881 306 -99 66 -241 183 -271 224 -17 23 -25 27 -40 19 -17 -9 -16 -13 12 -62 562 -959 668 -2451 295 -4157 -72 -332 -170 -685 -284 -1023 -33 -97 -54 -174 -48 -170 7 4 75 51 151 106 1016 727 1594 891 2261 642 252 -94 542 -271 860 -524 165 -132 153 -125 170 -111 12 10 4 25 -44 88 -304 399 -509 924 -591 1511 -63 456 -51 1033 35 1586 72 465 196 964 360 1445 68 200 188 511 244 634 17 38 30 70 29 71 -2 0 -106 -51 -233 -115z" />
              <path d="M9859 9317 c-90 -26 -169 -102 -199 -192 -21 -62 -16 -156 9 -209 29 -58 90 -119 149 -148 69 -33 178 -32 247 4 64 33 127 100 150 161 23 61 17 175 -13 232 -26 50 -84 107 -139 134 -54 27 -144 35 -204 18z" />
              <path d="M11390 9319 c-127 -19 -234 -73 -315 -160 -104 -111 -125 -214 -132 -656 l-6 -323 -178 0 -179 0 0 -155 0 -155 180 0 180 0 0 -1245 0 -1245 180 0 180 0 0 1245 0 1245 295 0 295 0 0 155 0 155 -296 0 -296 0 4 323 c3 309 5 324 26 368 24 50 75 92 139 115 83 29 264 9 401 -45 l52 -21 0 169 0 169 -107 26 c-182 43 -305 53 -423 35z" />
              <path d="M28360 8700 l0 -520 -245 0 -245 0 0 -155 0 -155 245 0 245 0 0 -1245 0 -1245 180 0 180 0 0 1245 0 1245 285 0 285 0 0 155 0 155 -285 0 -285 0 0 520 0 520 -180 0 -180 0 0 -520z" />
              <path d="M7500 7275 l0 -1895 915 0 915 0 0 185 0 185 -725 0 -725 0 0 1710 0 1710 -190 0 -190 0 0 -1895z" />
              <path d="M17710 7334 c-476 -1010 -877 -1863 -892 -1895 l-27 -59 208 0 207 0 294 620 293 620 821 0 822 0 295 -620 294 -619 202 -1 201 0 -96 208 c-54 114 -450 966 -882 1894 l-785 1688 -45 0 -45 -1 -865 -1835z m1237 344 l321 -683 -646 -3 c-355 -1 -647 -1 -649 1 -4 5 640 1367 647 1367 4 0 151 -307 327 -682z" />
              <path d="M13306 8240 c-329 -38 -575 -152 -799 -369 -253 -245 -408 -549 -452 -888 -63 -480 91 -939 430 -1285 174 -177 385 -292 635 -346 358 -77 746 -38 1033 104 273 134 473 333 641 636 l37 66 -144 76 c-79 42 -148 76 -153 76 -5 0 -34 -40 -64 -88 -70 -111 -195 -266 -255 -316 -127 -105 -300 -190 -480 -237 -78 -20 -114 -24 -270 -24 -157 0 -191 3 -265 23 -195 54 -308 120 -460 272 -86 86 -128 137 -167 204 -95 161 -147 331 -160 519 l-6 97 1258 0 1258 0 -6 108 c-17 286 -99 543 -245 762 -73 110 -239 281 -349 360 -164 117 -370 201 -578 235 -115 19 -337 26 -439 15z m436 -348 c302 -81 526 -245 661 -483 41 -73 135 -325 124 -335 -2 -2 -472 -3 -1044 -2 l-1041 3 32 95 c140 413 412 660 810 736 105 20 359 12 458 -14z" />
              <path d="M21465 8239 c-91 -13 -213 -54 -284 -96 -84 -49 -206 -171 -254 -253 -61 -106 -89 -206 -94 -345 -12 -309 108 -519 412 -720 44 -28 159 -95 256 -147 278 -151 403 -241 463 -336 75 -120 87 -280 29 -402 -90 -190 -265 -300 -480 -300 -186 0 -360 80 -527 244 -32 31 -61 56 -64 55 -4 0 -55 -56 -114 -124 l-108 -123 46 -51 c66 -73 192 -165 297 -216 328 -158 701 -148 961 26 88 58 198 172 250 257 89 147 131 347 108 516 -41 308 -207 489 -687 751 -303 165 -422 260 -486 390 -44 90 -52 201 -20 287 77 202 302 309 537 253 121 -28 258 -105 370 -208 44 -40 59 -49 72 -41 9 6 61 59 117 118 l100 108 -45 40 c-288 251 -573 357 -855 317z" />
              <path d="M23455 8239 c-91 -13 -213 -54 -284 -96 -84 -49 -206 -171 -254 -253 -61 -106 -89 -206 -94 -345 -12 -309 108 -519 412 -720 44 -28 159 -95 256 -147 278 -151 403 -241 463 -336 75 -120 87 -280 29 -402 -90 -190 -265 -300 -480 -300 -186 0 -360 80 -527 244 -32 31 -61 56 -64 55 -4 0 -55 -56 -114 -124 l-108 -123 46 -51 c66 -73 192 -165 297 -216 328 -158 701 -148 961 26 88 58 198 172 250 257 89 147 131 347 108 516 -41 308 -207 489 -687 751 -303 165 -422 260 -486 390 -44 90 -52 201 -20 287 77 202 302 309 537 253 121 -28 258 -105 370 -208 44 -40 59 -49 72 -41 9 6 61 59 117 118 l100 108 -45 40 c-288 251 -573 357 -855 317z" />
              <path d="M26016 8240 c-329 -38 -575 -152 -799 -369 -253 -245 -408 -549 -452 -888 -63 -480 91 -939 430 -1285 174 -177 385 -292 635 -346 358 -77 746 -38 1033 104 273 134 473 333 641 636 l37 66 -144 76 c-79 42 -148 76 -153 76 -5 0 -34 -40 -64 -88 -70 -111 -195 -266 -255 -316 -127 -105 -300 -190 -480 -237 -78 -20 -114 -24 -270 -24 -157 0 -191 3 -265 23 -195 54 -308 120 -460 272 -86 86 -128 137 -167 204 -95 161 -147 331 -160 519 l-6 97 1258 0 1258 0 -6 108 c-17 286 -99 543 -245 762 -73 110 -239 281 -349 360 -164 117 -370 201 -578 235 -115 19 -337 26 -439 15z m436 -348 c302 -81 526 -245 661 -483 41 -73 135 -325 124 -335 -2 -2 -472 -3 -1044 -2 l-1041 3 32 95 c140 413 412 660 810 736 105 20 359 12 458 -14z" />
              <path d="M9760 6780 l0 -1400 180 0 180 0 0 1400 0 1400 -180 0 -180 0 0 -1400z" />
            </g>
          </svg>
        </div>
      </div>
      <div className={style["dashboard-wrapper"]}>
        <div className={style["dashboard-box"]}>
          <div className={style["login-logo"]}>
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="3090.000000pt"
              height="40.000000pt"
              viewBox="0 0 3090.000000 1445.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,1445.000000) scale(0.100000,-0.100000)"
                fill="#000"
                stroke="none"
              >
                <path
                  d="M6403 13326 l-28 -24 -3 -5959 c-2 -4464 1 -5965 9 -5986 26 -62 104
-77 158 -31 l31 26 0 5973 0 5972 -26 27 c-36 35 -101 36 -141 2z"
                />
                <path d="M3365 10949 c-408 -47 -705 -382 -705 -794 0 -209 70 -390 209 -538 162 -172 356 -257 586 -257 230 0 424 85 586 257 139 148 209 329 209 538 0 139 -23 235 -84 360 -98 197 -279 349 -486 408 -47 14 -224 40 -240 36 -3 -1 -36 -5 -75 -10z" />
                <path d="M5045 9380 c-516 -261 -981 -412 -1431 -465 -150 -18 -457 -20 -594 -5 -318 35 -646 149 -881 306 -99 66 -241 183 -271 224 -17 23 -25 27 -40 19 -17 -9 -16 -13 12 -62 562 -959 668 -2451 295 -4157 -72 -332 -170 -685 -284 -1023 -33 -97 -54 -174 -48 -170 7 4 75 51 151 106 1016 727 1594 891 2261 642 252 -94 542 -271 860 -524 165 -132 153 -125 170 -111 12 10 4 25 -44 88 -304 399 -509 924 -591 1511 -63 456 -51 1033 35 1586 72 465 196 964 360 1445 68 200 188 511 244 634 17 38 30 70 29 71 -2 0 -106 -51 -233 -115z" />
                <path d="M9859 9317 c-90 -26 -169 -102 -199 -192 -21 -62 -16 -156 9 -209 29 -58 90 -119 149 -148 69 -33 178 -32 247 4 64 33 127 100 150 161 23 61 17 175 -13 232 -26 50 -84 107 -139 134 -54 27 -144 35 -204 18z" />
                <path d="M11390 9319 c-127 -19 -234 -73 -315 -160 -104 -111 -125 -214 -132 -656 l-6 -323 -178 0 -179 0 0 -155 0 -155 180 0 180 0 0 -1245 0 -1245 180 0 180 0 0 1245 0 1245 295 0 295 0 0 155 0 155 -296 0 -296 0 4 323 c3 309 5 324 26 368 24 50 75 92 139 115 83 29 264 9 401 -45 l52 -21 0 169 0 169 -107 26 c-182 43 -305 53 -423 35z" />
                <path d="M28360 8700 l0 -520 -245 0 -245 0 0 -155 0 -155 245 0 245 0 0 -1245 0 -1245 180 0 180 0 0 1245 0 1245 285 0 285 0 0 155 0 155 -285 0 -285 0 0 520 0 520 -180 0 -180 0 0 -520z" />
                <path d="M7500 7275 l0 -1895 915 0 915 0 0 185 0 185 -725 0 -725 0 0 1710 0 1710 -190 0 -190 0 0 -1895z" />
                <path d="M17710 7334 c-476 -1010 -877 -1863 -892 -1895 l-27 -59 208 0 207 0 294 620 293 620 821 0 822 0 295 -620 294 -619 202 -1 201 0 -96 208 c-54 114 -450 966 -882 1894 l-785 1688 -45 0 -45 -1 -865 -1835z m1237 344 l321 -683 -646 -3 c-355 -1 -647 -1 -649 1 -4 5 640 1367 647 1367 4 0 151 -307 327 -682z" />
                <path d="M13306 8240 c-329 -38 -575 -152 -799 -369 -253 -245 -408 -549 -452 -888 -63 -480 91 -939 430 -1285 174 -177 385 -292 635 -346 358 -77 746 -38 1033 104 273 134 473 333 641 636 l37 66 -144 76 c-79 42 -148 76 -153 76 -5 0 -34 -40 -64 -88 -70 -111 -195 -266 -255 -316 -127 -105 -300 -190 -480 -237 -78 -20 -114 -24 -270 -24 -157 0 -191 3 -265 23 -195 54 -308 120 -460 272 -86 86 -128 137 -167 204 -95 161 -147 331 -160 519 l-6 97 1258 0 1258 0 -6 108 c-17 286 -99 543 -245 762 -73 110 -239 281 -349 360 -164 117 -370 201 -578 235 -115 19 -337 26 -439 15z m436 -348 c302 -81 526 -245 661 -483 41 -73 135 -325 124 -335 -2 -2 -472 -3 -1044 -2 l-1041 3 32 95 c140 413 412 660 810 736 105 20 359 12 458 -14z" />
                <path d="M21465 8239 c-91 -13 -213 -54 -284 -96 -84 -49 -206 -171 -254 -253 -61 -106 -89 -206 -94 -345 -12 -309 108 -519 412 -720 44 -28 159 -95 256 -147 278 -151 403 -241 463 -336 75 -120 87 -280 29 -402 -90 -190 -265 -300 -480 -300 -186 0 -360 80 -527 244 -32 31 -61 56 -64 55 -4 0 -55 -56 -114 -124 l-108 -123 46 -51 c66 -73 192 -165 297 -216 328 -158 701 -148 961 26 88 58 198 172 250 257 89 147 131 347 108 516 -41 308 -207 489 -687 751 -303 165 -422 260 -486 390 -44 90 -52 201 -20 287 77 202 302 309 537 253 121 -28 258 -105 370 -208 44 -40 59 -49 72 -41 9 6 61 59 117 118 l100 108 -45 40 c-288 251 -573 357 -855 317z" />
                <path d="M23455 8239 c-91 -13 -213 -54 -284 -96 -84 -49 -206 -171 -254 -253 -61 -106 -89 -206 -94 -345 -12 -309 108 -519 412 -720 44 -28 159 -95 256 -147 278 -151 403 -241 463 -336 75 -120 87 -280 29 -402 -90 -190 -265 -300 -480 -300 -186 0 -360 80 -527 244 -32 31 -61 56 -64 55 -4 0 -55 -56 -114 -124 l-108 -123 46 -51 c66 -73 192 -165 297 -216 328 -158 701 -148 961 26 88 58 198 172 250 257 89 147 131 347 108 516 -41 308 -207 489 -687 751 -303 165 -422 260 -486 390 -44 90 -52 201 -20 287 77 202 302 309 537 253 121 -28 258 -105 370 -208 44 -40 59 -49 72 -41 9 6 61 59 117 118 l100 108 -45 40 c-288 251 -573 357 -855 317z" />
                <path d="M26016 8240 c-329 -38 -575 -152 -799 -369 -253 -245 -408 -549 -452 -888 -63 -480 91 -939 430 -1285 174 -177 385 -292 635 -346 358 -77 746 -38 1033 104 273 134 473 333 641 636 l37 66 -144 76 c-79 42 -148 76 -153 76 -5 0 -34 -40 -64 -88 -70 -111 -195 -266 -255 -316 -127 -105 -300 -190 -480 -237 -78 -20 -114 -24 -270 -24 -157 0 -191 3 -265 23 -195 54 -308 120 -460 272 -86 86 -128 137 -167 204 -95 161 -147 331 -160 519 l-6 97 1258 0 1258 0 -6 108 c-17 286 -99 543 -245 762 -73 110 -239 281 -349 360 -164 117 -370 201 -578 235 -115 19 -337 26 -439 15z m436 -348 c302 -81 526 -245 661 -483 41 -73 135 -325 124 -335 -2 -2 -472 -3 -1044 -2 l-1041 3 32 95 c140 413 412 660 810 736 105 20 359 12 458 -14z" />
                <path d="M9760 6780 l0 -1400 180 0 180 0 0 1400 0 1400 -180 0 -180 0 0 -1400z" />
              </g>
            </svg>
          </div>
          {!viewOtpForm && (
            <div className={style["login-box"]}>
              <h3>Sign in</h3>
              <p>
                <b>Sign in using your email address.</b>
              </p>
              <form id="loginForm" onSubmit={loginSubmit}>
                <div className={style["login-form"]}>
                  <div className={style["login-fields"]}>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                  <div className={style["login-captcha"]}>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_SITE_KEY + ""}
                      ref={captchaRef}
                    />
                  </div>
                </div>
                <div
                  className={`${style["form-buttons-wrapper"]} ${style["otp-button"]}`}
                >
                  <button
                    type="submit"
                    style={
                      isAwaiting
                        ? {
                            backgroundColor: "#cccccc",
                            color: "#666666",
                            cursor: "wait",
                          }
                        : {}
                    }
                  >
                    Send OTP
                  </button>
                </div>
              </form>
            </div>
          )}
          {viewOtpForm && (
            <div className={style["login-box"]} onSubmit={verifyOtp}>
              <h3>Verify OTP</h3>
              <p>
                <b>Enter the OTP sent to {email}:</b>
              </p>
              <form id="otpForm">
                <div className={style["login-form"]}>
                  <div className={style["login-fields"]}>
                    <input
                      id="otp"
                      name="otp"
                      type="text"
                      autoComplete="false"
                      placeholder="Enter OTP"
                      defaultValue={otp}
                      onChange={(event) => setOtp(event.target.value)}
                    />
                  </div>
                </div>
                <div className={`${style["form-buttons-wrapper"]}`}>
                  <a
                    className={`${style["sec-btn"]}`}
                    type="button"
                    onClick={() => {
                      setViewOtpForm(false);
                    }}
                  >
                    Back
                  </a>
                  <button
                    type="submit"
                    style={
                      isAwaiting
                        ? {
                            backgroundColor: "#cccccc",
                            color: "#666666",
                            cursor: "wait",
                          }
                        : {}
                    }
                  >
                    Verify OTP
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default OTPAuth;
