import React, { useEffect, useState } from "react";
import style from "../../../style.module.css";
import ErrorLabel from "../error-label/error-control";

type SelectInputProps = {
  label: string;
  name: string;
  options: Array<string>;
  selectedOption: string;
  handleInputChange: (event: any) => any;
  triggerValidation: any;
};

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  name,
  options,
  selectedOption,
  handleInputChange,
  triggerValidation = null,
}) => {
  const [error, setError] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [fieldStatus, setFieldStatus] = useState<boolean>(false);

  const handleChange = (value: any, name: any) => {
    setSelectedValue(value);
    setError(true);
    handleInputChange({ name, value });
  };

  useEffect(() => {
    setSelectedValue(selectedOption);
    if (
      Object.keys(triggerValidation).length &&
      triggerValidation[name] != undefined &&
      triggerValidation[name]
    ) {
      setError(true);
    }
  }, [triggerValidation]);

  return (
    <div className={style["form-box"]}>
      <div className={style["form-label"]}>{label}</div>
      <div className={style["input-box"]}>
        <select
          onChange={(e: React.ChangeEvent<{ value: string }>) =>
            handleChange(e.target.value, name)
          }
          name={name}
        >
          <option value="">Select</option>
          {options.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <option value={item} selected={item == selectedOption}>
                {item}
              </option>
            </React.Fragment>
          ))}
        </select>
        <div className={style["down-arrow"]}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
          </svg>
        </div>
      </div>
      {error && (
        <ErrorLabel
          name={name}
          value={selectedValue}
          required={true}
          message={`${label} is required`}
          setFieldValid={setFieldStatus}
        />
      )}
    </div>
  );
};

export default SelectInput;
