import React from "react";
import FormInput from "../../containers/form/input/input-control";
import RadioInput from "../form/radio/radio-component";

interface Props {
  applicantNo: number;
  triggerValidation: any;
  formValues: any;
  handleInputChange: any;
  prefix?: string;
}

const Address: React.FC<Props> = ({
  applicantNo,
  triggerValidation,
  formValues,
  handleInputChange,
  prefix = "",
}) => {
  return (
    <>
      <FormInput
        label={"Present Address"}
        name={`${prefix}address_${applicantNo}`}
        value={formValues[`${prefix}address_${applicantNo}`]}
        type="text"
        handleInputChange={handleInputChange}
        triggerValidation={triggerValidation}
      />
      <FormInput
        label={"City"}
        name={`${prefix}city_${applicantNo}`}
        value={formValues[`${prefix}city_${applicantNo}`]}
        type="text"
        handleInputChange={handleInputChange}
        triggerValidation={triggerValidation}
      />
      <FormInput
        label={"State/Province"}
        name={`${prefix}state_${applicantNo}`}
        value={formValues[`${prefix}state_${applicantNo}`]}
        type="text"
        handleInputChange={handleInputChange}
        triggerValidation={triggerValidation}
      />
      <FormInput
        label={"State Code"}
        name={`${prefix}sc_${applicantNo}`}
        value={formValues[`${prefix}sc_${applicantNo}`]}
        type="text"
        handleInputChange={handleInputChange}
        triggerValidation={triggerValidation}
      />
      <FormInput
        label={"PostalCode"}
        name={`${prefix}postal_code_${applicantNo}`}
        value={formValues[`${prefix}postal_code_${applicantNo}`]}
        type="text"
        handleInputChange={handleInputChange}
        triggerValidation={triggerValidation}
      />
      <FormInput
        label={"County"}
        name={`${prefix}county_${applicantNo}`}
        value={formValues[`${prefix}county_${applicantNo}`]}
        type="text"
        handleInputChange={handleInputChange}
        triggerValidation={triggerValidation}
      />
      <FormInput
        label={"Country"}
        name={`${prefix}country_${applicantNo}`}
        value={formValues[`${prefix}country_${applicantNo}`]}
        type="text"
        handleInputChange={handleInputChange}
        triggerValidation={triggerValidation}
      />
      <RadioInput
        key={`${prefix}is_bus_addr_${applicantNo}`}
        handleInputChange={handleInputChange}
        label={"Is this a Business Address?"}
        name={`${prefix}is_bus_addr_${applicantNo}`}
        options={["Yes", "No"]}
        triggerValidation={triggerValidation}
        selectedValue={formValues[`${prefix}is_bus_addr_${applicantNo}`]}
      />
    </>
  );
};

export default Address;
