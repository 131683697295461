import { useNavigate } from "react-router-dom";
import style from "../../style.module.css";

const NotFound: React.FC<any> = () => {
  const navigate = useNavigate();
  return (
    <div className={style["not-found-container"]}>
      <h1 className={style["not-found-header"]}>404</h1>
      <p className={style["not-found-message"]}>
        Sorry, the page you are looking for could not be found.
      </p>
      <div className={style["form-buttons-wrapper"]}>
        <a
          onClick={() => {
            navigate("/redirect");
          }}
        >
          Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
