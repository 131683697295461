import React, { useEffect, useState } from "react";
import style from "../../../style.module.css";
import FormInput from "../../../containers/form/input/input-control";
import SelectInput from "../../../containers/form/select/select-component";
import RadioInput from "../../../containers/form/radio/radio-component";
import { LOAN_DETAILS_VALIDATIONS } from "../../../common/validations/loan-fields";

interface LoanDetailsValues {
  requested_amount: number;
  term_requested: any;
  payment_date: any;
  payment_type: string;
  purpose: string;
  loan_type: string;
}

interface Props {
  applicantNo?: any;
  validateSchema: boolean;
  isPrevAvailable: boolean;
  isNextAvailable: boolean;
  isLastPage?: boolean;
  handlePagination: (isNext: boolean) => any;
  handleSubNavigation: (formValues: any, submitForm?: boolean) => any;
}

const LoanDetailsForm: React.FC<Props> = ({
  applicantNo = 1,
  validateSchema,
  isPrevAvailable,
  isNextAvailable,
  handlePagination,
  handleSubNavigation,
  isLastPage,
}) => {
  const [formValues, setFormValues] = useState<any>({});
  const [allLoanFields, setAllLoanFields] = useState<any>({});
  const [triggerValidation, setTriggerValidation] = useState({});

  const triggerValidationForAllValues = () => {
    var trObj: any = {};
    Object.keys(formValues).forEach((item: any) => {
      trObj[item] = true;
    });
    setTriggerValidation(trObj);
  };

  useEffect(() => {
    const loanFormInStorage: any = JSON.parse(
      localStorage.getItem(`loan_applicant_${applicantNo}`) ?? "{}"
    );

    Object.keys(loanFormInStorage).forEach((key: any) => {
      formValues[key] = loanFormInStorage[key];
    });
    const loanDetailsFormFields = LOAN_DETAILS_VALIDATIONS;
    Object.entries(loanDetailsFormFields).forEach((item: any) => {
      if (item[1].required) {
        if (
          formValues[item[0]] == undefined ||
          formValues[item[0]].toString().trim() == ""
        ) {
          setAllLoanFields((prev: any) => ({ ...prev, [item[0]]: false }));
          formValues[item[0]] = "";
        } else {
          setAllLoanFields((prev: any) => ({ ...prev, [item[0]]: true }));
        }
      }
    });
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    if (validateSchema) {
      triggerValidationForAllValues();
    }
  }, [validateSchema]);

  const handleInputChange = (target: any) => {
    var field = target.name;
    var trigObj: any = {};
    trigObj[field] = false;
    if (
      target.error ||
      !target.value ||
      target.value == undefined ||
      target.value.toString().trim() == ""
    ) {
      setAllLoanFields((prev: any) => ({ ...prev, [field]: false }));
      setTriggerValidation(trigObj);
    } else {
      setAllLoanFields((prev: any) => ({ ...prev, [field]: true }));
      trigObj[field] = true;
      setTriggerValidation(trigObj);
    }
    setFormValues({ ...formValues, [target.name]: target.value });
  };

  const handleNextClick = () => {
    localStorage.setItem(
      `loan_applicant_${applicantNo}`,
      JSON.stringify(formValues)
    );
    triggerValidationForAllValues();
    if (!getLoanFormValidationStatus()) {
      return;
    }

    handleSubNavigation(formValues);
  };

  const handlePreviousClick = (isNext: boolean) => {
    localStorage.setItem(
      `loan_applicant_${applicantNo}`,
      JSON.stringify(formValues)
    );
    handlePagination(isNext);
  };

  const scrollToElement = (elementName: string) => {
    const element = document.getElementsByName(elementName)[0];
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.pageYOffset - 60,
      behavior: "smooth",
    });
  };

  const getLoanFormValidationStatus = () => {
    var elementInView = false;
    var formStatus = true;
    Object.entries(allLoanFields).forEach((item: any) => {
      if (!item[1]) {
        if (!elementInView) {
          scrollToElement(item[0]);
        }
        elementInView = true;
      }
      formStatus = formStatus && item[1];
    });
    return formStatus;
  };

  return (
    <div className={style["form-wrapper"]}>
      <div className={style["form-header"]}>
        <div className={style["form-box-wrapper"]}>
          <div className={style["form-sub-heading"]}>Loan Information</div>
          <div className={style["form-sub-section"]}>
            <FormInput
              label={"Amount Requested"}
              name={`requested_amount`}
              value={formValues.requested_amount}
              type="number"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Term Requested (in months)"}
              name={`term_requested`}
              value={formValues.term_requested}
              type="number"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <FormInput
              label={"Desired Payment Date"}
              name={`payment_date`}
              value={formValues.payment_date}
              type="date"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <SelectInput
              handleInputChange={handleInputChange}
              label="Desired Repayment"
              name={`payment_type`}
              options={["Bi-Weekly", "Semi-Monthly", "Monthly"]}
              triggerValidation={triggerValidation}
              selectedOption={formValues.payment_type}
            />
            <FormInput
              label={"Loan Purpose"}
              name={`purpose`}
              value={formValues.purpose}
              type="text"
              handleInputChange={handleInputChange}
              triggerValidation={triggerValidation}
            />
            <RadioInput
              key={`loan_type`}
              handleInputChange={handleInputChange}
              label={"Loan Category"}
              name={`loan_type`}
              options={["Secured", "Unsecured"]}
              triggerValidation={triggerValidation}
              selectedValue={formValues[`loan_type`]}
            />
          </div>
        </div>
        <div
          className={`${
            isPrevAvailable
              ? style["form-buttons-wrapper"]
              : style["form-buttons-wrapper"] + " " + style["single-button"]
          }`}
        >
          {isPrevAvailable && (
            <a
              onClick={() => {
                handlePreviousClick(false);
              }}
              className={style.secondary}
            >
              Previous
            </a>
          )}
          {isNextAvailable && (
            <a
              onClick={() => {
                handleNextClick();
              }}
            >
              Next
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoanDetailsForm;
