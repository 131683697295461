import React from "react";
import style from "../../style.module.css";

type BreadCrumpsProps = {
  sections: Array<string>;
  selectedSectionIndex: number;
  handleOnClick: (event: any) => any;
};

const BreadCrumps: React.FC<BreadCrumpsProps> = ({
  sections,
  selectedSectionIndex,
  handleOnClick,
}) => {
  const handleChange = (index: any) => (event: any) => {
    handleOnClick(index);
  };

  return (
    <div className={style["nav-breadcrumbs-outer"]}>
            <div className={style["nav-breadcrumbs"]}>
              {sections.map((item: any, index: number) => (
                <a
                  onClick={handleChange(index)}
                  className={
                    style[`${selectedSectionIndex == index ? "active" : ""}`]
                  }
                  key={index}
                >
                  <span className={style["step-number"]}>{index + 1}</span>
                  <span className={style["step-name"]}>{item}</span>
                </a>
              ))}
            </div>
          </div>
  );
};

export default BreadCrumps;
