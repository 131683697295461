import React, {useState } from "react";
import style from "../../../style.module.css";

type CheckBoxInputProps = {
  label?: string;
  name?: string;
  value?: any;
  type?: string;
  isHidden?: boolean;
  handleInputChange?: (event: any) => any;
  triggerValidation?: any;
};

const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  label = "",
  name = "",
  value = "",
  handleInputChange = null,
  isHidden = false,
}) => {
  const [inputValue, setInputValue] = useState<any>(value);
  const [fieldStatus, setFieldStatus] = useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    handleInputChange && handleInputChange({ name, checked, fieldStatus });
  };

  return (
    <>
      {!isHidden && (
        <>
           <div className={`${style["form-box"]} ${style["full-width"]}`}>
            <label className={style["checkbox-container"]}>
              <input
                type="checkbox"
                value={inputValue}
                name={name}
                onChange={handleChange}
              />
              <span className={style["checkmark"]}></span>
              <span className={style["checkbox-text"]}>
                {label}
              </span>
            </label>
          </div>
        </>
      )}
    </>
  );
};

export default CheckBoxInput;
